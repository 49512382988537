import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import slidebgimg from "./images/pro/probg1 (1).jpg";
import slidebgimg222 from "./images/pro/probg2 (1).jpg";
import slidebgimg333 from "./images/pro/probg3 (1).png";
import backboneimg from "./images/pro/backbone.png";
import legboneimg from "./images/pro/legbone.png"

const StyledText = styled.p`
  font-family: 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  height:100rem,
  width:100rem,
  top: 50%;
  transform: translateY(-50%);
  background-color: black; /* Change arrow color to black */
  opacity: 1; /* Adjust arrow opacity */
  padding: 10px;
  z-index: 1; /* Ensure arrows are on top of images */
  cursor: pointer;
`;

const LeftArrow = styled(ArrowWrapper)`
width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    transform: translatex(-100%);
    -webkit-transform: translatex(-100%);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    
`;

const RightArrow = styled(ArrowWrapper)`
width: 100%;
height: 100%;
position: absolute;
top: 0px;
left: 0px;
background-position: center center;
background-size: cover;
border-radius: 50%;
transform: translatex(-100%);
-webkit-transform: translatex(-100%);
transition: all 0.3s;
-webkit-transition: all 0.3s;

`;
const Procure = () => {
  return (
    <>
      <StyledText>
        <div>
          <div id="box-wrapper">
            <div className="fusion-sides-frame"></div>
            <div id="wrapper" className='fusion-wrapper'>
              <div id="home" style={{ position: 'relative', top: '-1px' }}></div>
              <div className=''>
                <div className='row'>
                  <div className="container">
                   

                    <Carousel>
                      <Carousel.Item>
                        <img src={slidebgimg222} alt="Chania" width="1920px" height="950px" />
                        
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={slidebgimg} alt="Chania" width="1920px" height="950px" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={slidebgimg222} alt="Flower" width="1920px" height="950px" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={slidebgimg333} width="1920px" height="950px" />
                       
                          
                        
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
                <div className='row'>
                  <div className='post-content'>
                  <div className="fusion-fullwidth fullwidth-box fusion-builder-row-3 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling fusion-no-small-visibility fusion-no-medium-visibility">
      <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start skskskskskskdd" style={{  marginLeft: 'calc(-4% / 2)', marginRight: 'calc(-4% / 2)' }}>
      <div className="fusion-layout-column fusion_builder_column">
        
      <div className="fusion-layout-column fusion_builder_column">
        {/* ... Other components ... */}
        <div className="fusion-title title fusion-title-1 cotentpropinkbg">
          <h2 className="title-heading-left fusion-texthh22">Clinically Proven Safe Treatment for the Whole Family</h2>
        </div>
        <div className="fusion-text fusion-text-1 fusion-textppp">
          <p>FDA Cleared Physiotherapy Device For Pain Relief At Home</p>
        </div>
        <div className="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start fusionbuilderrow">
  <div className="column fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-0 fusion_builder_column_inner_1_6 1_6 fusion-flex-column fusion-no-small-visibilitykjkk">
    <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
      {/* ... */}
      <div className='' style={{textAlign:'center'}}>
      <span className="imageframe fusion-imageframe imageframe-none imageframe-3 hover-type-none">
        <img
          decoding="async"
          width="84"
          height="85"
          title="Easily applied"
          src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
          data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
          
          alt="Home"
        />
      </span></div>
      <div className='fusion-text fusion-text-2 iconimgbelowtext'>
        <p>Easily applied</p>
      </div>
      {/* ... */}
    </div>
    <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column222">
      {/* ... */}
      <div className=''  style={{textAlign:'center'}}>
      <span className="imageframe fusion-imageframe imageframe-none imageframe-3 hover-type-none">
        <img 
          decoding="async"
          width="84"
          height="85"
          title="Easily applied"
          src="https://www.powercure.com/wp-content/uploads/2022/06/Safety.png"
          data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
         
          alt="Home"
        />
      </span></div>
      <div className='fusion-text fusion-text-2 iconimgbelowtext'>
        <p>Safety & Fast <br/>Efficiency</p>
      </div>
      {/* ... */}
    </div>
    <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column222">
      {/* ... */}
      <div className=''  style={{textAlign:'center'}}>
      <span className="imageframe fusion-imageframe imageframe-none imageframe-3 hover-type-none">
        <img 
          decoding="async"
          width="84"
          height="85"
          title="Easily applied"
          src="https://www.powercure.com/wp-content/uploads/2022/06/Mode-Adjustable.png"
          data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
         
          alt="Home"
        />
      </span></div>
      <div className='fusion-text fusion-text-2 iconimgbelowtext'>
        <p>Mode Adjustable</p>
        {/* <p>(Pulse, CW mode)</p> */}
      </div>
      {/* ... */}
    </div>
    <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column222">
      {/* ... */}
      <div className=''  style={{textAlign:'center'}}>
      <span className="imageframe fusion-imageframe imageframe-none imageframe-3 hover-type-none">
        <img 
          decoding="async"
          width="84"
          height="85"
          title="Easily applied"
          src="https://www.powercure.com/wp-content/uploads/2022/06/Portable-Handheld.png"
          data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
         
          alt="Home"
        />
      </span></div>
      <div className='fusion-text fusion-text-2 iconimgbelowtext'>
        <p>Portable Handheld</p>
      </div>
      {/* ... */}
    </div>
    <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column222">
      {/* ... */}
      <div className=''  style={{textAlign:'center'}}>
      <span className="imageframe fusion-imageframe imageframe-none imageframe-3 hover-type-none">
        <img 
          decoding="async"
          width="84"
          height="85"
          title="Easily applied"
          src="https://www.powercure.com/wp-content/uploads/2022/06/Cost-effective.png"
          data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
         
          alt="Home"
        />
      </span></div>
      <div className='fusion-text fusion-text-2 iconimgbelowtext'>
        <p>Cost effective</p>
      </div>
      {/* ... */}
    </div>
    <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column222">
      {/* ... */}
      <div className=''  style={{textAlign:'center'}}>
      <span className="imageframe fusion-imageframe imageframe-none imageframe-3 hover-type-none">
        <img 
          decoding="async"
          width="84"
          height="85"
          title="Easily applied"
          src="https://www.powercure.com/wp-content/uploads/2022/06/Non-invasive-No-pain.png"
          data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Easily-applied.png"
         
          alt="Home"
        />
      </span></div>
      <div className='fusion-text fusion-text-2 iconimgbelowtext'>
        <p>Non-invasive No pain</p>
      </div>
      {/* ... */}
    </div></div></div>
        {/* ... Other components ... */}
      </div></div></div></div>
                  </div>
                </div>
                <div className='row'>
                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" id="thirdrdprosection">
      <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start howtextoneff">
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-6 fusion_builder_column_1_1 1_1 fusion-flex-column">
          <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
            <div className="fusion-title title fusion-title-3 fusion-no-small-visibility fusion-no-medium-visibility333 fusion-sep-none fusion-title-text fusion-title-size-two">
              <h2 className="title-heading-left h2titl1head44">
                <p>How Can I get relief from pain at home?</p>
              </h2>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-6 fusion-flex-container  fusion-no-medium-visibility444 ">
      <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start" >
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-7 fusion_builder_column_1_1 1_1 fusion-flex-column fourimgrowkfoucolll">
          <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column fourimgrowkfoucol222" >
            <div className="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start fourimgrowkfoucolrow" >
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-12 fusion_builder_column_inner_1_4 1_4 fusion-flex-column fourimgcol1part">
                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column fourimgcol1subpartt" >
                  <div>
                    <div className='usenotdiv'></div>
                  <span className="fusion-imageframe imageframe-none imageframe-15 hover-type-none spanfouimgpart1">
                      <img decoding="async"   title="pain1" src="https://www.powercure.com/wp-content/uploads/2022/06/pain1.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain1.png" className="img-responsive wp-image-419 lazyautosizes ls-is-cached lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain1-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain1.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain1-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain1.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span>
                    <div>
                    <div className='usenotdiv'></div>
                    <span className="fusion-imageframe imageframe-none imageframe-16 hover-type-none spanfouimgpart1">
                      <img decoding="async"   title="pain3" src="https://www.powercure.com/wp-content/uploads/2022/06/pain3.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain3.png" className="img-responsive wp-image-421 lazyautosizes ls-is-cached lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain3-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain3.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain3-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain3.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span>
                    </div>
                    <div>
                    <div className='usenotdiv'></div>
                    <span className="fusion-imageframe imageframe-none imageframe-17 hover-type-none spanfouimgpart1">
                      <img decoding="async"  title="pain5" src="https://www.powercure.com/wp-content/uploads/2022/06/pain5.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain5.png" className="img-responsive wp-image-423 lazyautosizes lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain5-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain5.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain5-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain5.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span>
                    </div>
                    <div>
                    <div className='usenotdiv'></div>
                    <span className="fusion-imageframe imageframe-none imageframe-18 hover-type-none spanfouimgpart1">
                      <img decoding="async"  title="pain7" src="https://www.powercure.com/wp-content/uploads/2022/06/pain7.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain7.png" className="img-responsive wp-image-425 lazyautosizes ls-is-cached lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain7-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain7.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain7-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain7.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span></div>
                    </div>
                    </div></div>
                    <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-12 fusion_builder_column_inner_1_4 1_4 fusion-flex-column fourimgcol1part">
                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column fourimgcol1subpartt" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', backgroundColor: '#f5f5f5', padding: '0px 0px 0px 0px' }}>
                  <div>
                    <div className='usenotdiv'></div>
                  <span className="fusion-imageframe imageframe-none imageframe-15 hover-type-none spanfouimgpart1">
                      <img decoding="async"   title="pain1" src="https://www.powercure.com/wp-content/uploads/2022/06/pain1.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain1.png" className="img-responsive wp-image-419 lazyautosizes ls-is-cached lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain1-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain1.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain1-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain1.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span>
                    <div>
                    <div className='usenotdiv'></div>
                    <span className="fusion-imageframe imageframe-none imageframe-16 hover-type-none spanfouimgpart1">
                      <img decoding="async"   title="pain3" src="https://www.powercure.com/wp-content/uploads/2022/06/pain3.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain3.png" className="img-responsive wp-image-421 lazyautosizes ls-is-cached lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain3-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain3.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain3-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain3.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span>
                    </div>
                    <div>
                    <div className='usenotdiv'></div>
                    <span className="fusion-imageframe imageframe-none imageframe-17 hover-type-none spanfouimgpart1">
                      <img decoding="async"  title="pain5" src="https://www.powercure.com/wp-content/uploads/2022/06/pain5.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain5.png" className="img-responsive wp-image-423 lazyautosizes lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain5-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain5.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain5-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain5.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span>
                    </div>
                    <div>
                    <div className='usenotdiv'></div>
                    <span className="fusion-imageframe imageframe-none imageframe-18 hover-type-none spanfouimgpart1">
                      <img decoding="async"  title="pain7" src="https://www.powercure.com/wp-content/uploads/2022/06/pain7.png" data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/pain7.png" className="img-responsive wp-image-425 lazyautosizes ls-is-cached lazyloaded spanfouimgpart1" srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain7-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain7.png 301w" data-srcSet="https://www.powercure.com/wp-content/uploads/2022/06/pain7-200x136.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/pain7.png 301w" data-sizes="auto" data-orig-sizes="301px" data-orig-="" alt="Home" sizes="301px" />
                    </span></div>
                    </div>
                    </div></div>
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-14 fusion_builder_column_inner_1_2 1_2 fusion-flex-column fusion-flex-align-self-center fourimagetextconrow">
                <div className="fusion-column-wrapper fusion-flex-justify-content-center fusion-content-layout-column fourimagetextcontent" >
                  <div className="fusion-text fusion-text-15">
                    <p className='fourimagetextcontentpppp'>With the fast pace and high pressure of modern social life, people’s bodies are under increasing load, and sub-healthy physical condition seems to be a common phenomenon. Many people are suffering from neck pain, frozen shoulder, and tenosynovitis for a long time.</p>
                    <p className='fourimagetextcontentpppp'>With the increase of age, the joints of the human body gradually age, and the physical pain will become more and more obvious, and even induce various inflammations, such as arthritis, back pain. We may even encounter motor muscle injuries, skin abrasions, burns, etc. Currently, there is no cure for these chronic pains, so people have been looking for a quick and efficient way to relieve pain at home.</p>
                    <p className='fourimagetextcontentpppp'>Cold Laser Therapy or Low-Level Laser Therapy, as a clinically proven one of the best and most effective solutions for treating chronic pain, all without invasive surgery, prescribing drugs, or painkillers.</p>
                  </div>
                </div> </div>
            
          </div>
          </div>
      </div>
    </div>
                  </div>
                </div>
                <div className='row'>
                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-10 fusion-flex-container hundred-percent-fullwidth non-hundred-percent-height-scrolling fusion-no-small-visibility fusion-no-medium-visibility procuresectionfustiowidth5">
      <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start procuresectionfustiowdhrow">
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-11 fusion_builder_column_1_1 1_1 fusion-flex-column fusion-flex-align-self-flex-start procuresectionfustiowdhcol111">
          <div className="fusion-column-wrapper fusion-flex-justify-content-center fusion-content-layout-column procuresectionfuswdhcol1part22">
            <div className="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start procuresectionfuswdhco">
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-19 fusion_builder_column_inner_1_2 1_2 fusion-flex-column fusion-flex-align-self-center procuresectionfulayout1">
                <div className="fusion-column-wrapper fusion-flex-justify-content-center fusion-content-layout-column procuresectionfusubbbblayout1">
                  <div className="fusion-title title fusion-title-5 fusion-sep-none fusion-title-center fusion-title-text fusion-title-size-two procuresectionfusubbbblayouparagrapgdiv">
                    <h2 className="title-heading-center">
                      <p>What Is Low-Level Laser Therapy?</p>
                    </h2>
                  </div>
                  <div className="fusion-text fusion-text-17">
                    <p className='procuresectionfusubbbblayouparagrapgdipppp'>Low-Level Laser Therapy(LLLT) or Cold Laser Therapy is the application of low-power light energy, within the visible red and near-infrared band of the electromagnetic spectrum, utilizes specific wavelengths of light to interact with tissue and to help eliminate pain, swelling, reduce spasms, and increase functionality. LLLT works deep within the cells to speed up repair and improve functionality.</p>
                    <p className='procuresectionfusubbbblayouparagrapgdipppp'>It is a safe and effective therapy for a wide range of medical conditions, including soft tissue and sports injuries, arthritic conditions, repetitive stress injuries, wound healing, and an extensive number of additional medical problems.</p>
                  </div>
                </div>
              </div>
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-20 fusion_builder_column_inner_1_2 1_2 fusion-flex-column profusiolayo222">
                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column pprofusiolayocolwrapp">
                  <div>
                    <span className="fusion-imageframe imageframe-none imageframe-31 hover-type-none pprofusiolayocolwraspannn">
                      <img className="pprofusiolayocolwraspanimgg"
                        src="https://www.powercure.com/wp-content/uploads/2022/06/POWERCURE1.jpg"
                        alt="Home"
                        
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
                </div>
                <div className='row'>
                  <div className="fusion-fullwidth fullwidth-box profustionrowwidth">
      <div className="fusion-builder-row fusion-row profustionsubrow22">
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-13 profustionscol133">
          <div className="fusion-column-wrapper profustionscolwraperkk">
            <div className="fusion-title title fusion-title-7 profustionscoltitlehdiv">
              <h2 className="title-heading-left profustionscoltitleh22">
                <p>Advantage TECHNOLOGY</p>
              </h2>
            </div>
            <div className="fusion-builder-row fusion-builder-row-inner fusion-row profustiotechimgrow">
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-21 profustiotechimgrlayout">
                <div className="fusion-column-wrapper profustiotechimgrlawrapperr">
                  <div>
                    <span className="fusion-imageframe imageframe-none imageframe-32 hover-type-none profustiotechimgrlawimgframe">
                      <img className='profustiotechimgrlawimgsrc'
                        src="https://www.powercure.com/wp-content/uploads/2022/06/Advantage-TECHNOLOGY1.png"
                        alt="Advantage TECHNOLOGY1"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-22  profustiotechicol222">
                <div className="fusion-column-wrapper profustiotechicol2wrapper">
                  <div className="fusion-text fusion-text-19 profustiotechicol2wrppp">
                    <p className='profustiotechicol2wrpppstrong'>
                      <p>Significant Effect:</p>
                    </p>
                    <p className='profustiotechicol2wrpppppptext'>
                      Medical 4pcs 808nm and home 12pcs 650nm laser diode are
                      perfectly combined to effectively treat acute and chronic
                      pain
                    </p>
                    <p className='profustiotechicol2wrpppstrong'>
                      <p>Innovative Technology:</p>
                    </p>
                    <p  className='profustiotechicol2wrpppppptext'>
                      New analgesic laser treatment equipment Pulse, CW two
                      modes combine to penetrate the deep muscles
                    </p>
                    <p className='profustiotechicol2wrpppstrong'> 
                      <p>LCD Screen &amp; Long Battery Life:</p>
                    </p>
                    <p className='profustiotechicol2wrpppppptext'>
                      Φ50mm big white LED display, Clear view of the treatment
                      time, power, battery capacity, and working mode. A
                      built-in rechargeable 3400mah lithium battery
                    </p>
                  </div>
                </div>
              </div>
              <div className="fusion-builder-row fusion-builder-row-inner fusion-row profustiotechimgrow">
  <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-22 profustiotechicol222">
    <div className="fusion-column-wrapper profustiotechicol2wrapper">
      <div className="fusion-text fusion-text-19 profustiotechicol2wrppp">
        <p className='profustiotechicol2wrpppstrong'>
          <p>Systemic Physiotherapy Safe And Effective:</p>
        </p>
        <p className='profustiotechicol2wrpppppptext'>
        Systemic treatment of the joints and acupuncture point Safe, non-invasive 
        technology without any side effects,FDA Cleared Class II Medical Device
         featuring Laser Therapy,Clinical evidence and safe treatment
        </p>
        <p className='profustiotechicol2wrpppstrong'>
          <p>Comfortable And Convenient To Use:</p>
        </p>
        <p className='profustiotechicol2wrpppppptext'>
        Wireless portable products, specially designed for families, use spherical
        lenses, directly contact the skin when using, no need to hang in the air
        </p>
        
      </div>
    </div>
  </div>
  <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-24 profustiotechimgrlayout">
    <div className="fusion-column-wrapper profustiotechimgrlawrapperr">
      <div>
        <span className="fusion-imageframe imageframe-none imageframe-33 hover-type-none profustiotechimgrlawimgframe">
          <img className='profustiotechimgrlawimg2222'
            src="https://www.powercure.com/wp-content/uploads/2022/06/Advantage-TECHNOLOGY2.png"
            alt="Advantage TECHNOLOGY2"
          />
        </span>
      </div>
    </div>
  </div>
</div>
            </div>
          </div>
        </div>
      </div>
    </div></div>
    <div className='row'>
    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-14 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling fusion-no-small-visibility fusion-no-medium-visibility profullwidthsection5" >
      <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start fusion-flex-justify-content-flex-end profullwidthsection5rowww">
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-15 fusion_builder_column_1_1 1_1 fusion-flex-column profullwidthsection5rco21211">
          <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column profullwidthsection5rocolll" >
          <div className="fusion-title title fusion-title-9 fusion-sep-none fusion-title-text fusion-title-size-two profullwidthsection5rocolhpdiv" style={{ marginTop: '10px', marginRight: '0px', marginBottom: '4%', marginLeft: '0px' }}>
              <h2 className="prosec5titleh2" >
                <p  className="prosec5titlepp">Why PowerCure?</p>
              </h2>
            </div>
            <div className="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start fusion-flex-justify-content-flex-end prsectiondivrow222"  style={{ width: '104% !important', maxWidth: '104% !important', marginLeft: 'calc(1% )', marginRight: 'calc(1%)' }}>
              
              <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-25 fusion_builder_column_inner_1_5 1_5 fusion-flex-column prsectiondivcol2upp" style={{marginLeft:'',marginTop:'3%'}}>
                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column prsectiondivcol22" >
                  <div>
                    <span className=" fusion-imageframe imageframe-none imageframe-36 hover-type-none proset5imagframespan">
                      <img  src={backboneimg}
                             width="254"
                             height="198"
                             title="Pain Relief"
                             
                            
                             dataSizes="auto"
                             dataOrigSizes="254px"
                             dataOrig=""
                             alt="Home"
                             sizes="219px"
                      />
                    </span>
                  </div>
                  <div className="fusion-text fusion-text-23 proset5imagframespipdiv" >
                    <p  className ="proset5imagframespipppp">Pain Relief</p>
                  </div>
                  <div>
                    <span className=" fusion-imageframe imageframe-none imageframe-37 hover-type-none proset5imagframespan">
                      <img
                        decoding="async"
                        width="254"
                        height="198"
                        title="Rheumatoid Arthritis"
                        src="https://www.powercure.com/wp-content/uploads/2022/06/Rheumatoid-Arthritis.png"
                        data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Rheumatoid-Arthritis.png"
                        className="img-responsive wp-image-517 lazyautosizes ls-is-cached lazyloaded"
                        srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Rheumatoid-Arthritis-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Rheumatoid-Arthritis.png 254w"
                        dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Rheumatoid-Arthritis-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Rheumatoid-Arthritis.png 254w"
                        dataSizes="auto"
                        dataOrigSizes="254px"
                        dataOrig=""
                        alt="Home"
                        sizes="219px"
                      />
                    </span>
                  </div>
                  <div className="fusion-text fusion-text-24 proset5imagframespipdiv">
                    <p style={{ textAlign: 'center' }}>Rheumatoid Arthritis</p>
                  </div>
                </div></div>
                <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-26 fusion_builder_column_inner_1_5 1_5 fusion-flex-column prsectiondivcol2upp" style={{marginLeft:''}}>
      <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column prsectiondivcol22" >
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-38 hover-type-none proset5imagframespan">
            <img
              decoding="async"
              width="261"
              height="204"
              title="Skin Would Healing"
              src="https://www.powercure.com/wp-content/uploads/2022/06/Skin-Would-Healing.png"
              data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Skin-Would-Healing.png"
              className="img-responsive wp-image-518 lazyautosizes ls-is-cached lazyloaded"
              srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Skin-Would-Healing-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Skin-Would-Healing.png 261w"
              dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Skin-Would-Healing-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Skin-Would-Healing.png 261w"
              dataSizes="auto"
              dataOrigSizes="261px"
              dataOrig=""
              alt="Home"
              sizes="219px"
            />
          </span>
        </div>
        <div className="fusion-text fusion-text-25 fusion-text-no-margin proset5imagframespipdiv222" style={{ fontSize: '24px', fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen-Sans, Ubuntu, Cantarell, \'Helvetica Neue\', sans-serif', fontWeight: '400', marginBottom: '60%' }}>
          <p style={{ textAlign: 'center' }}>Skin Would Healing</p>
        </div>
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-39 hover-type-none">
            <img
              decoding="async"
              width="267"
              height="209"
              title="Wound & Ulcer Acupuncture"
              src="https://www.powercure.com/wp-content/uploads/2022/06/Wound.png"
              data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Wound.png"
              className="img-responsive wp-image-519 lazyautosizes ls-is-cached lazyloaded"
              srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Wound-200x157.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Wound.png 267w"
              dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Wound-200x157.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Wound.png 267w"
              dataSizes="auto"
              dataOrigSizes="267px"
              dataOrig=""
              alt="Home"
              sizes="219px"
            />
          </span>
        </div>
        <div className="fusion-text fusion-text-26" style={{ fontSize: '24px', lineHeight: '24px' }}>
          <p style={{ textAlign: 'center' }}>Wound & Ulcer</p>
          <p style={{ textAlign: 'center' }}>Acupuncture</p>
        </div>
      </div></div>
      <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-27 fusion_builder_column_inner_1_5 1_5 fusion-flex-column prsectiondivcol2upp" style={{marginTop:'10%'}} >
      <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column prsectiondivcol22" >
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-40 hover-type-none proset5imagframespan">
            <img
              decoding="async"
              width="293"
              height="294"
              title="Application"
              src="https://www.powercure.com/wp-content/uploads/2022/06/Application.png"
              data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Application.png"
              className="img-responsive wp-image-520 lazyautosizes ls-is-cached lazyloaded"
              srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Application-200x201.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Application.png 293w"
              dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Application-200x201.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Application.png 293w"
              dataSizes="auto"
              dataOrigSizes="293px"
              dataOrig=""
              alt="Home"
              sizes="219px"
            />
          </span>
        </div>
      </div></div>
      <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-28 fusion_builder_column_inner_1_5 1_5 fusion-flex-column prsectiondivcol2upp" >
      <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column prsectiondivcol22">
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-41 hover-type-none proset5imagframespan">
            <img
              decoding="async"
              width="266"
              height="209"
              title="Diminish Inflammation"
              src="https://www.powercure.com/wp-content/uploads/2022/06/Diminsh-Inflammation.png"
              data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Diminsh-Inflammation.png"
              className="img-responsive wp-image-521 lazyautosizes ls-is-cached lazyloaded"
              srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Diminsh-Inflammation-200x157.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Diminsh-Inflammation.png 266w"
              dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Diminsh-Inflammation-200x157.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Diminsh-Inflammation.png 266w"
              dataSizes="auto"
              dataOrigSizes="266px"
              dataOrig=""
              alt="Diminish Inflammation"
              sizes="219px"
            />
          </span>
        </div>
        <div className="fusion-text fusion-text-27 fusion-text-no-margin" style={{ fontSize: '24px', lineHeight: '24px', marginBottom: '60%' }}>
          <p style={{ textAlign: 'center' }}>Diminish Inflammation</p>
        </div>
        
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-42 hover-type-none proset5imagframespan">
            <img
              decoding="async"
              width="267"
              height="208"
              title="Soft Tissue Injuries"
              src="https://www.powercure.com/wp-content/uploads/2022/06/Soft-Tissue-Injuries.png"
              data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Soft-Tissue-Injuries.png"
              className="img-responsive wp-image-522 lazyautosizes ls-is-cached lazyloaded"
              srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Soft-Tissue-Injuries-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Soft-Tissue-Injuries.png 267w"
              dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Soft-Tissue-Injuries-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Soft-Tissue-Injuries.png 267w"
              dataSizes="auto"
              dataOrigSizes="267px"
              dataOrig=""
              alt="Soft Tissue Injuries"
              sizes="219px"
            />
          </span>
        </div>
        <div className="fusion-text fusion-text-28" style={{ fontSize: '24px' }}>
          <p style={{ textAlign: 'center' }}>Soft Tissue Injuries</p>
        </div>
      </div></div>
      <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-29 fusion_builder_column_inner_1_5 1_5 fusion-flex-column prsectiondivcol2upp">
      <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column prsectiondivcol22" >
      <div>
                    <span className=" fusion-imageframe imageframe-none imageframe-36 hover-type-none proset5imagframespan">
                      <img  src={legboneimg}
                             width="254"
                             height="198"
                             title="Pain Relief"
                             
                            
                             dataSizes="auto"
                             dataOrigSizes="254px"
                             dataOrig=""
                             alt="Home"
                             sizes="219px"
                      />
                    </span>
                  </div>
                  <div className="fusion-text fusion-text-23 proset5imagframespipdiv" >
                    <p  className ="proset5imagframespipppp">Pain Relief</p>
                  </div>
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-43 hover-type-none proset5imagframespan">
            <img
              decoding="async"
              width="254"
              height="198"
              title="Sports Injuries & Sprain"
              src="https://www.powercure.com/wp-content/uploads/2022/06/Sports-Injuries-Sprain.png"
              data-orig-src="https://www.powercure.com/wp-content/uploads/2022/06/Sports-Injuries-Sprain.png"
              className="img-responsive wp-image-524 lazyautosizes ls-is-cached lazyloaded"
              srcSet="https://www.powercure.com/wp-content/uploads/2022/06/Sports-Injuries-Sprain-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Sports-Injuries-Sprain.png 254w"
              dataSrcSet="https://www.powercure.com/wp-content/uploads/2022/06/Sports-Injuries-Sprain-200x156.png 200w, https://www.powercure.com/wp-content/uploads/2022/06/Sports-Injuries-Sprain.png 254w"
              dataSizes="auto"
              dataOrigSizes="254px"
              dataOrig=""
              alt="Home"
              sizes="219px"

            />
          </span>
        </div>
        <div className="fusion-text fusion-text-29" style={{ fontSize: '24px' }}>
          <p style={{ textAlign: 'center' }}>Sports Injuries & Sprain</p>
        </div>
      </div></div>
                </div>
          </div>
          </div>
      </div></div>
    </div>
    <div className='row'>
    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-16 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling prospecificationfullwidt">
      <div >
        <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start prospecificationfulroww" >
          <div className="fusion-layout-column fusion_builder_column fusion-builder-column-17 fusion_builder_column_1_1 1_1 fusion-flex-column ">
            <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column prospecificationfucolllwrapp" >
            <div className="fusion-title title fusion-title-11 fusion-sep-none fusion-title-text fusion-title-size-two prospecificationfucolllwtitle{" >
                <h2 className="title-heading-left" style={{ margin: '0' }}>
                  <p className='prospecificationfucolllwtippp'>Specifications</p>
                </h2>
              </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    <div className='row'>
      <div className='fulwidspecideviirowcoll'>
        <div className='fulwidspecideviinf22222'>

        </div>
        {/* <div className=''> */}
          <div className='protabledivmain'>
            <div className='protableboderstyl'>
        <div className="table-1protab">
                      <table width="100%" className='protablestartcs'>
                        <tbody className='protabtbodysec'>
                          <tr>
                            <td className='protabtbotdd' align="center">Laser Medium</td>
                            <td className='protabtbotdd' align="center">GaAIAs semiconductor</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Laser Wavelength</td>
                            <td  className='protabtbotdd' align="center">808nm±20nm（4PCS）<br />
                              650nm±20nm（12PCS）</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Maximum Output Power<br />
                              per 808nm Laser Diode</td>
                            <td className='protabtbotdd'  align="center">250mW±20%</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Maximum Output Power<br />
                              per 650nm Laser Diode</td>
                            <td className='protabtbotdd' align="center">5mW±20%</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Total Output Power</td>
                            <td className='protabtbotdd' align="center">1060mW±20%</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Working Mode</td>
                            <td className='protabtbotdd' align="center">Continuous/Pulse</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Time Setting</td>
                            <td className='protabtbotdd' align="center">5 to 30 minutes,<br />
                              5 minutes as an interval</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Battery Capacity</td>
                            <td className='protabtbotdd' align="center">3400mAh lithium battery</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Charging Port</td>
                            <td className='protabtbotdd' align="center">DC5V, 2A</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Environment Temperature</td>
                            <td className='protabtbotdd' align="center">5℃～40℃</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Relative Humidity</td>
                            <td className='protabtbotdd' align="center">20～80%</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Atmospheric Pressure</td>
                            <td className='protabtbotdd' align="center">860hpa ~ 1060hpa</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Adapter Output</td>
                            <td className='protabtbotdd' align="center">DC5V⎓2A</td>
                          </tr>
                          <tr className='protabttr22'>
                            <td className='protabtbotdd' align="center">Adapter Voltage</td>
                            <td className='protabtbotdd' align="center">AC100-240V</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Net Weight</td>
                            <td className='protabtbotdd' align="center">216±5g</td>
                          </tr>
                        </tbody>
                      </table>
                      </div>  </div></div>
        {/* </div> */}

      </div>
      
    {/* <div className="fusion-fullwidth fullwidth-box fusion-builder-row-17 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling fusion-no-small-visibility fusion-no-medium-visibility fulwidspecideviinfo"  >
      <div >
        <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start fulwidspecideviirow" >
          <div className="fusion-layout-column fusion_builder_column fusion-builder-column-18 fusion_builder_column_1_1 1_1 fusion-flex-column fulwidspecideviirowcoll">
            <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column lazyloaded fulwidspecideviinf22222" >
              <div className="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start fulwidspecidevbuilderow" >
                <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-38 fusion_builder_column_inner_1_2 1_2 fusion-flex-column fusion-flex-align-self-flex-end fulwidspecidevbuilderowlayoycol">
                  <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column fulwidspecidevbuilderowlayoycollaywrappe"></div>
                  </div>
                <div className="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-39 fusion_builder_column_inner_1_2 1_2 fusion-flex-column fulwidspecidevbuildecollayout22">
                  <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column fulwidspecidevbuildelayimggg" >
                    <div className="table-1">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td className='protabtbotdd' align="center">Laser Medium</td>
                            <td className='protabtbotdd' align="center">GaAIAs semiconductor</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Laser Wavelength</td>
                            <td className='protabtbotdd' align="center">808nm±20nm（4PCS）<br />
                              650nm±20nm（12PCS）</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Maximum Output Power<br />
                              per 808nm Laser Diode</td>
                            <td className='protabtbotdd' align="center">250mW±20%</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Maximum Output Power<br />
                              per 650nm Laser Diode</td>
                            <td className='protabtbotdd' align="center">5mW±20%</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Total Output Power</td>
                            <td className='protabtbotdd' align="center">1060mW±20%</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Working Mode</td>
                            <td className='protabtbotdd' align="center">Continuous/Pulse</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Time Setting</td>
                            <td className='protabtbotdd' align="center">5 to 30 minutes,<br />
                              5 minutes as an interval</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Battery Capacity</td>
                            <td className='protabtbotdd' align="center">3400mAh lithium battery</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Charging Port</td>
                            <td className='protabtbotdd' align="center">DC5V, 2A</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Environment Temperature</td>
                            <td className='protabtbotdd' align="center">5℃～40℃</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Relative Humidity</td>
                            <td className='protabtbotdd' align="center">20～80%</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Atmospheric Pressure</td>
                            <td className='protabtbotdd' align="center">860hpa ~ 1060hpa</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Adapter Output</td>
                            <td className='protabtbotdd' align="center">DC5V⎓2A</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Adapter Voltage</td>
                            <td className='protabtbotdd' align="center">AC100-240V</td>
                          </tr>
                          <tr>
                            <td className='protabtbotdd' align="center">Net Weight</td>
                            <td className='protabtbotdd' align="center">216±5g</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div> </div>
      </div></div> */}
    </div>
              </div>
            </div>
          </div>
        </div>
      </StyledText>
    </>
  )
}

export default Procure
