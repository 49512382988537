import React from 'react'
import "./Contact.css"
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
const StyledText = styled.p`
  font-family: 'Raleway', sans-serif;
  
`;
const Contact = () => {
    const location = useLocation();
    const isContactPage = location.pathname === '/contact';
  return (
    <div className='maincontact'>
        <StyledText>
        <div className={isContactPage ? '_contactbgimge' : ''}>
            <div className='container contactcon'>
                <div className='row'>
                    <div className='col-7 contactmainpcol'>
                        <div>
                        <section id="content-section-2">
      <div className="section-container container">
        <div className="content-itemform" >
          <p></p>
          <div className="conatctspace" ></div>
          <h5 className="conatctformh5" >Please fill the form below.</h5>
          <div className="gdlr-space" ></div>

          <div className="wpcf7 js" lang="en-US" dir="ltr">
            
            <form action="" method="post" className="wcontactform" aria-label="Contact form" noValidate="novalidate" >
        
            <p className='_formfield'>
                <label className='_formfieldlabel'>
                    Your name<br />
                    <span className="contformspan" data-name="your-name">
                        <input 
                        className='contformstextarea'
                            size="40"
                            
                            aria-required="true"
                            aria-invalid="false"
                            value=""
                            type="text"
                            name="your-name"
                        />
                    </span>
                </label>
            </p>
            <p>
                <label className='_formfieldlabel'>
                    Your email<br />
                    <span className="contformspan" data-name="your-email">
                        <input
                       className='contformstextarea'
                            size="40"
                            
                            aria-required="true"
                            aria-invalid="false"
                            value=""
                            type="email"
                            name="your-email"
                        />
                    </span>
                </label>
            </p>
            <p>
                <label className='_formfieldlabel'>
                    Subject<br />
                    <span className="contformspan" data-name="your-subject">
                        <input
                       className='contformstextarea'
                            size="40"
                            
                            aria-required="true"
                            aria-invalid="false"
                            value=""
                            type="text"
                            name="your-subject"
                        />
                    </span>
                </label>
            </p>
            <p>
                <label className='_formfieldlabel'>
                    Your message (optional)<br />
                    <span className="contformspan" data-name="your-message">
                        <textarea
                        className='contformstextarea'
                            cols="40"
                            rows="10"
                           
                            aria-invalid="false"
                            name="your-message"
                        ></textarea>
                    </span>
                </label>
            </p>
            <p className='submitcontactppp'>
                <input
                    className="submitcontactpppinput"
                    type="submit"
                    value="Submit"
                />
                <span className="wpcf7-spinner"></span>
            </p>
            
             
            </form>
          </div>
        </div>
      </div>
    </section>
                        </div>

                    </div>
                    <div className='col-4 rightcontsidecontact'>
                        
                            <div className='start_rightcontent'>
                            <h3 className="contactinformationtitle">Contact Sales &amp; Support</h3>
                            <div className='contacttextwidget'>
                            <p className='contacttextwidgetp'>Want to get in touch? We’d love to hear from you. Please see below for some quick ways to reach us. Interested in learning more about our devices, treatments, or services? Just pick up the phone to chat with a member of our sales team.</p>
                            </div>
                            </div>
                            <div className='contactinformation'>
                            <h3 class="contactinformationtitle">Contact Information</h3>
                            <div class="contactinformationdetails">
                                <p className='contactinformationpppp'><i className="fa fa-map-marker contacticon" ></i> 3077 Woodbine Drive, North Vancouver, BC V7R <br/>2S3 Canada</p>
<p className='contactinformationpppp'><i className="gdlr-icon fa fa-phone contacticon" ></i> +1 (604) 649-1234</p>
<p className='contactinformationpppp'><i className="gdlr-icon fa fa-envelope contacticon" ></i> info@pro1laser.com</p>
<p className='contactinformationpppp'><i className="gdlr-icon fa fa-clock-o  contacticon" ></i> Everyday 9:00-17:00</p>
</div>
<div className="socialmediaicionti">
    <h3 className="socialmediaiciontih3">Social Media</h3>
    <div className='.socialmedia-icons'>
    <a className="socialmediaiciontiaaa" href="http://facebook.com/goodlayers"><i className="fa fa-facebook socialmediaicionaaa" ></i></a> 
    <a className="socialmediaiciontiaaa" href="http://twitter.com/goodlayers"><i class="fa fa-twitter socialmediaicionaaa" ></i></a> 
    <a className="socialmediaiciontiaaa"href="#"><i className="fa fa-dribbble socialmediaicionaaa" ></i></a> 
    <a className="socialmediaiciontiaaa" href="#"><i className="fa fa-pinterest socialmediaicionaaa" ></i></a> 
    <a  className="socialmediaiciontiaaa"href="#"><i className="fa fa-google-plus socialmediaicionaaa" ></i></a> 
    <a className="socialmediaiciontiaaa"href="#"><i className="fa fa-instagram socialmediaicionaaa" ></i></a>
</div>
		</div>
                            </div>
                        
                    </div>
                </div>
            </div>
            <div className="cpontactbelowwrapper">
            <section id="contactsection-3">
            <div
          className=" contactbelowbgimg"
          
        >
             <div className="container contactbelowcontainer">
                <div className='row'>
                    <div className='col-4 contactbeowcol'>
                        <div className='contactbeowcontent'>
                        <div className="gdlr-item gdlr-box-with-icon-item pos-top type-circle">
                        <div className="box-with-circle-icon" >
                    <i className="fa fa-envelope box-withicon" ></i>
                    <br /></div> </div>
                    <h4 className="box-with-icon-title">Contact By Email</h4>
                    <dib className="box-with-icon-caption">
                    <p>Interested in learning more about our devices, treatments, or services?<br/> Contact us on the email: <br/>info@pro1laser.com</p>
                    </dib>
                  
                       
                        </div>
                        
                    </div>
                    <div className='col-4 contactbeowcol'>
                        <div className='contactbeowcontent'>
                        <div className="gdlr-item gdlr-box-with-icon-item pos-top type-circle">
                        <div className="box-with-circle-icon" >
                    <i className="fa fa-phone box-withicon" ></i>
                    <br /></div> </div>
                    <h4 className="box-with-icon-title">Contact By Phone</h4>
                    <dib className="box-with-icon-caption">
                    <p>Just pick up the phone to chat with a <br/>member of our sales team. Phone: +1 (604)<br/> 649-1234</p>
                    </dib>
                  
                       
                        </div>
                        
                    </div>                    
                    <div className='col-4 contactbeowcol'>
                        <div className='contactbeowcontent'>
                        <div className="gdlr-item gdlr-box-with-icon-item pos-top type-circle">
                        <div className="box-with-circle-icon" >
                    <i className="fa fa-home box-withicon" ></i>
                    <br /></div> </div>
                    <h4 className="box-with-icon-title">Come To See Us</h4>
                    <dib className="box-with-icon-caption">
                    <p>3077 Woodbine Drive, North Vancouver, BC V7R 2S3 Canada</p>
                    </dib>
                  
                       
                        </div>
                        
                    </div>
                </div>
                
             </div>
          </div>

            </section>
            </div>
            <footer className='footer-wrapper contactfooter'>
           <div className="container contactfootcont">
            <div className='row'>
                <div className='contfootcol'>
                <div className="contfootcntent1">
                    <h2 className="contfootcntent1h2">
	Get updates on our newest innovations,<br/>treatment <span className="contfootcntent1span"> technologies </span> and more
	</h2>

	[email-subscribers-form id="1"]
</div>
                </div>
            </div>
           </div>

            </footer>
            </div>
        </StyledText>
     
    </div>
  )
}

export default Contact
