import React from 'react';
import "./Footer.css";
import footerlogo from "./images/footerlogo.png"

const Footer = () => {
  return (
    <div>
      {/* <div className='footbackgrounddiv'></div> */}
    <footer className="footer actualfooterdiv">
    <div className="container pb-30 footcont">
    <img src={footerlogo} className='footerlogimg' alt="NewsMe Logo" />
    </div>
    <div className="container footcot2" >
    <div className="row">
          <div className="col-lg-4 col-sm-4 col-xs-12">
          <div className="single_footer">
          <h4 className="singlefooterh4">WHY PRO1LASER CONCEPT?
            </h4>
            {/* <span className='singlefooterafter'></span> */}
          <ul className="singlefooteulll">
                <li><a href="#" data-track="">Benefits & Services</a></li>
                <li><a href="#" data-track="">Medical Devices</a></li>
                <li><a href="#" data-track="">Aesthetic Devices</a></li>
                <li><a href="#" data-track="">News Events</a></li>
                <li><a href="#" data-track="">Investor Relation</a></li>
              </ul>
          </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer">
              <h4 className="singlefooterh4">Page Link
              </h4>
              
              <ul className="singlefooteulll">
                <li><a href="#" data-track="">About</a></li>
                <li><a href="#" data-track="">Blog</a></li>
                <li><a href="#" data-track="">Products</a></li>
                <li><a href="#" data-track="">Contact</a></li>
                <li><a href="#" data-track="">Careers</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer">
              <h4 className="singlefooterh4">Subscribe today</h4>
           
              <div className="signup_form">
                <form action="#" className="subscribeform">
                  <input type="text" className="subscribeinput" placeholder="Enter Email Address" />
                  <button type="button" className="subscribe__btn">
                    <i className="fas fa-paper-plane suscribeicon"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="social_profile">
              <ul className="socialprofileul">
                <li><a href="#" data-track=""><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#" data-track=""><i className="fab fa-twitter"></i></a></li>
                <li><a href="#" data-track=""><i className="fab fa-google-plus-g"></i></a></li>
                <li><a href="#" data-track=""><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          </div>
    </div>
    </footer>
    </div>
  )
}

export default Footer
