import React from 'react'
import "./Product.css";
import styled from 'styled-components';
import fatremoveimg from "./images/aftsave.jpg";
import {  useNavigate} from 'react-router-dom';
import product1imgbgless from "./images/neremovebg.png";
import emshapelogo from "./images/emshape-1-removebg.png";
import product1scultmgbgless from "./images/sculpt-removebg.png";
import getfitlogo from "./images/get.png"
const StyledText = styled.p`
  font-family: 'Raleway', sans-serif;
  
`;


const Product = ({ closeDropdown }) => {
  const history = useNavigate();
  const handleProductClick = (path) => {
    history(path);
    closeDropdown();
  };
  
  return (
    <>
    
    <StyledText>
      
    <div className="producdropdown">
    <div  className="producdropdownmenu">
        <section>
            <div className='container-fluid productmaincont'>
                <div className='row productmainrow'>
                    <div className='col-12 col-lg-8 productmaincol1'>
                    {/* <div  className="megaMenu-nav-wrapper product-menu"> */}
                    <ul className='mainuiiiii'>
                       
                    <li className='mainliii'>
                        <ul className='prodytdropdownul'>
                            <li className='prodytdropdownliii'>
                            <a className='prodytdropdownaaaaaa'    >Skin</a>
                            <ul className='prodytdropsubdownui'>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa' onClick={() => handleProductClick('/aft')}>
          PRO LUX AFT
          </a>
                            </li>
                            <li className='prodytdropsubdownlii'>
                            <img src={product1imgbgless} style={{width:'97px'}} className='hairproduct1aa' onClick={() => handleProductClick('/aft')}/>     
                            </li>
                           
                            
                            
                            </ul>
                            </li>

                            <li className='prodytdropdownliii'>
                            <a className='prodytdropdownaaaaaa'  >Fat Remove</a>
                            <ul className='prodytdropsubdownui'>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa' onClick={() =>  handleProductClick('/emphase')} >
           EMSHAPE 
          </a>
                            </li>
                           
                           
                           
                            <li className='prodytdropsubdownlii'>
                            <img src={emshapelogo} style={{width:'111px',position:'relative',right:'10px',bottom:'9px'}}  className='hairproduct1aa' onClick={() =>  handleProductClick('/emphase')} />
           
          
                            </li>
                            </ul>
                            </li>
                            <li className='prodytdropdownliii'>
                            <a className='prodytdropdownaaaaaa'  >Hair</a>
                            <ul className='prodytdropsubdownui'>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa' onClick={() =>  handleProductClick('/sculpt')}>
                            PRO SCULPT
          </a>
                            </li>
                            <li className='prodytdropsubdownlii'>
                            <img src={product1scultmgbgless} style={{width:'97px'}} className='hairproduct1aa' onClick={() => handleProductClick('/sculpt')}/>         
                            </li>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa'>
                            {/* BodyProduct@3 */}
          </a>
                            </li>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa'>
                            {/* BodyProduct@4 */}
          </a>
                            </li>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa'>
                            {/* BodyProduct@15 */}
          </a>
                            </li>
                            
                            
                            </ul>
                             </li>    
                             <li className='prodytdropdownliii'>
                            <a className='prodytdropdownaaaaaa'  >FITNESS</a>
                            <ul className='prodytdropsubdownui'>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa' onClick={() => handleProductClick('/getfit')}>
                            GET FIT
          </a>
                            </li>
                            <li className='prodytdropsubdownlii'>
                            <img src={getfitlogo } style={{width:'62%',position:'relative',right:'9px',bottom:'10px',marginTop:'33%'}} className='hairproduct1aa' onClick={() => handleProductClick('/getfit')}/>         
                            </li>
                            
                            
                            </ul>
                            </li>
                            <li className='prodytdropdownliii'>
                            <a className='prodytdropdownaaaaaa'  >WOmen Health</a>
                            <ul className='prodytdropsubdownui'>
                            <li className='prodytdropsubdownlii'>
                            <a className='hairproduct1aa'>
                            Womanproduct@1
          </a>
                            </li></ul>
                           
      </li>
      </ul>
      </li>
      <li>

        <li className='mainliii'>
      
    <li className="contactdropbelow">
                            <a className='contactdropbelowaaa'>VIEW ALL DEVICES</a></li>
                            <li className="contactdropbelow">
                            <a className='contactdropbelowaaa'>COMPARE PRODUCTS</a></li>
                        </li>
                        </li></ul>

                    {/* </div> */}
                    

                    </div>
                    <div className='col-12 col-lg-4 productmaincol2'>
                        <div className='pructsideimg'>
                        <img src={fatremoveimg} className='ftrremovimg' />
                        </div>
                        <div className='contactintro'>
                            <div className='productsubtitle'>INTRODUCING PRO1LASER</div>
                            <p className='productintropp'>Welcome to Pro 1 Laser Canadas most technologically advanced and Health Canada approved IPL Manufacturer an we also engaged in Designing, Manufacturing and Marketing Aesthetic Devices
</p>
                            <p className='productintroppppp2'><a  className='productintropbutton' href=""  data-track="">LEARN MORE</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    </div>
    </StyledText>
    </>
  )
}

export default Product
