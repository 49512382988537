import logo from './logo.svg';
import './App.css';
import { Route,Link,Routes } from 'react-router-dom';
import Home from './component/Home';
import Header from './component/Header';
import Blog from './component/Blog';
import 'font-awesome/css/font-awesome.min.css';
import Getfit from './component/Getfit';
import './global.css'; 
import Footer from './component/Footer';

import Contact from './component/Contact';
import Product from './component/Product';
import Aftprodut from './component/Aftprodut';
import Emphaseproduct from './component/Emphaseproduct';
import Sculpt from './component/Sculpt';
import Contact2 from './component/Contact2';
import Procure from './component/Procure';





function App() {
  return (
    <div >
    
     <Header/>
        <Routes>
          
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path ="/blog" element={<Blog/>}/>
        <Route path ="/contact" element={<Contact/>}/>
        <Route path="/aft" element={<Aftprodut/>}/>
        <Route path="/getfit" element={<Getfit/>}/>
        <Route path="/emphase" element={<Emphaseproduct/>}/>
        <Route path="/sculpt" element={<Sculpt/>}/>
        <Route path="/procure" element={<Procure/>}/>
        <Route path="/contact222" element={<Contact2/>}/>
        
       
        
        
        </Routes>
       <Footer/>
    </div>
  );
}

export default App;
