import React from 'react'
import "./Contact22.css";

const Contact2 = () => {
  return (
    <div>
      
    </div>
  )
}

export default Contact2
