import React from 'react'
import './Home.css'
import { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import 'font-awesome/css/font-awesome.min.css'; 
import styled from 'styled-components';
import product1img from "./images/ne.jpg";
import product2img from "./images/emshape-1.jpg";
import product3img from "./images/sculpt.png";
import brandproduct1img from "./images/bradpruduct1.png";
import brandproduct2img from "./images/bradpruduct2.png";
import brandproduct3img from "./images/bradpruduct3.png";
import brandproduct4img from "./images/bradpruduct5.png";
import pamplateimg from "./images/pampalte.png"
import headimg from "./images/head.png";
import loactionimg from "./images/location.png";
import mailimg from "./images/mail.png"
import callimg from "./images/call.png";
import footerlogo from "./images/footerlogo.png";
import { useNavigate } from 'react-router-dom';



const StyledText = styled.p`
  font-family: 'Raleway', sans-serif;
  
`;

const Home = () => { 
  
const [regionListVisible, setRegionListVisible] = useState(false);
const [isSearchOpen, setSearchOpen] = useState(false);
const history = useNavigate();
const location= useLocation();
const isHomePage = location.pathname === '/';
const handleSearchToggle = () => {
    setSearchOpen(prevState => !prevState);
  };
  const toggleRegionList = () => {
    setRegionListVisible(!regionListVisible);
  };
    const handleViewProductClick = () => {
      history("/aft");
    };
  // useEffect(() => {
  //   const logoSlice = document.querySelector('.logoSlice');
    
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       logoSlice.classList.add('stickyLogoSlice');
  //     } else {
  //       logoSlice.classList.remove('stickyLogoSlice');
  //     }
  //   };
    
  //   window.addEventListener('scroll', handleScroll);
    
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);



return (
  <StyledText>
    <div>
  <div className='bigbgmaindiv'>
      <section id="top">
        <div className={isHomePage ? '_topImage' : ''}>
  {/* <div id='header'> */}
  {/* <div className="mini-grey-slice  _uppertop">
    
    <section id="bootstrapsliceMiniGrey"> 
      <div className="container-fluid">
        <div className="row rowcnt2">
          <div className="col-sm-5 _firstid">
            <div className="seacontainerrch-">
              <div className="items cnt2">
                <div className="item id1 first">
                  <div className="contact-info">
                    <p className="tel">
                      TEL:
                      <a
                        className="top-of-page mobilenoclass"
                        href="tel:1-888-907-0115"
                      >
                        +1 (604) 649-1234
                      </a>
                    </p>
                  </div>
                </div>
                
                <div className="item id2 last search-bar">
                 

                  <div className="searchWrapper-variantA">
      <a className="search-image" onClick={handleSearchToggle}>
        <img src={Search} alt="Search" />
      </a>
      {isSearchOpen && (
        <div className="search-box">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <input id="search"  placeholder="Search" type="text" className='_searchbox'/>
        </div>
      )}
    </div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-sm-7 _rowsm">
            <div className=" _rowsm2">
              <div >
                <div className="items cnt1">
                  <div>
                    <div>
                      <div className="top-utility-nav">
                        <div className="btn-group btn-group-padded">
                         
                          <div className={`dropdown ${regionListVisible ? 'show' : ''}`}>
                            <button
                              id="selectLang"
                              className="selectLang dropdiv"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              style={{
                                backgroundImage: `url(${require('./images/gl.png').default})`,
                                backgroundPosition: 'right center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'auto 100%',
                                height: '15px'
                              }}
                              onClick={toggleRegionList}
                            >
                              Select a region
                              <span className="dropdown _dropglob"></span>
                              Global
                            </button>
                            <ul id="regionlist" className={`dropdown-menu ${regionListVisible ? 'show' : ''}`}>
                              
                            </ul>
                          </div>
                        </div>

                        <div className="btn-group btn-group-padded">
                        <span className="_normline">|</span>
                          <a className="xselectLang" href="" target="_blank">
                            ARE YOU A PATIENT?
                          </a>
                          <div className="extra-cell socialicon">
                          <div className="extra-cell-wrapper">
  <ul className="socialiconul">
    <li className="socialiconli">
      <a target="_blank" href={""} className="site-button-link">
        <img src={Facbook} alt="Facebook"  className="imgicon"/>
      </a>
    </li>
    <li className="socialiconli">
      <a target="_blank" href={""} className="site-button-link">
        <img src={Google_plus} alt="Google-Plus" className="imgicon" />
      </a>
    </li>
    <li className="socialiconli">
      <a target="_blank"  href={""} className="site-button-link">
        <img src={Tumblr} alt="Tumblr" className="imgicon" />
      </a>
    </li>
    <li className="socialiconli">
      <a target="_blank"  href={""}className="site-button-link">
        <img src={Twitter} alt="Twitter" className="imgicon" />
      </a>
    </li>
    <li className="socialiconli">
      <a target="_blank"  href={""}className="site-button-link">
        <img src={vimbo} alt="Vimbo" className="imgicon" />
      </a>
    </li>
    <span className="_normline">|</span>
  </ul>
</div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    
        </div> */}
          {/* <div className='overlay logoSlice'>
          <div className="container logoSlicontain">
          <div class="row cnt2">
          <div class="col-xs-3 col-md-2">
          <p class="text-left-he">
            <a  className="newsmeimgclass" data-track="link | Click | Home Page - Logo" href="/"><img src={newsme} alt="Venus Concept Logo, links to homepage" width="210" height="50" /></a>
            </p>
          </div>
          <div className="col-xs-9 col-md-10 navdiv">
  <nav className="navbar _navbar_deafult">
    <div className="container _navcon">
      <ul className="nav navbar-nav ml-auto">
        <li className="nav-item _navbarlist">
          <a className="_navlistaaa">Home</a>
        </li>
        <li className="nav-item _navbarlist">
          <a className="_navlistaaa">Products</a>
        </li>
        <li className="nav-item _navbarlist">
          <a className="_navlistaaa">Blog</a>
        </li>
        <li className="nav-item _navbarlist">
          <a className="_navlistaaa">LipoLax</a>
        </li>
        <li className="nav-item _navbarlist">
          <a className="_navlistaaa">Contact</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
</div>

          </div></div> */}
          {/* </div> */}

<div class="container conten1div">
<div class="row cnt1 conten1row">
<div class="col-md-8 first last id1 conten1col" >
<div class="bannerPaddingRight">
                             <div className='items cnt1'>
                              <div className='item id1 first last'>          <h1 id='welcomspan'>
                                          <span >Welcome to Pro 1 Laser </span>
                                        </h1>
                                        <p className='_welcompro'>
                                          Welcome to Pro 1 Laser Canadas most
                                          technologically advanced 
                                          and Health Canada approved IPL Manufacturer
                                        </p>
                                       
                                        <p id='bttonphome'>
                                          <a href="#" className="btn btn-white" id="_whitbutton" data-track="" style={{width:'11.625rem',fontSize:'0.625rem'}}>+1 (604) 649-1234</a>
                                          <a aria-label="Opens the contact dialog" id="_bluebutton" href="#" className="btn btn-blue expertCall" data-track="" style={{width:'9.28rem',fontSize:'0.625rem'}}>Contact Us</a>
                                        </p>
                                       
                                        </div>   </div></div>
</div>
</div>
</div>





          
          
         
         
          
  </div>
      </section>
      
    </div>
    <div className="item id2">
<section id="globalLeader">
<div id="" className="dgray text-center padding75 gloalid">
<div className="container _globalcont">
<div className="row gloabrow">
<div className="col-md-8 col-md-offset-2 gloacol">
<div className="items cnt1">
<h2 className='gloabh2'>
                              PRO 1 LASER: A Professional Company
                              engaged in Designing, Manufacturing and
                              Marketing Aesthetic Devices
                            </h2>
                            <p className='_gloabp'>
                                        We want to help you build a thriving
                                        aesthetic business with unlimited
                                        training and marketing support. Our
                                        advanced technology and devices empower
                                        you to specialize in delivering safe and
                                        effective treatments giving you a
                                        distinct competitive advantage in both
                                        performance and clinical results.
                                      </p>
                                      <p className='bluesedulep'>
                                        <a aria-label="Opens the contact dialog" data-track="link | Click | Home Page - Speak to an Expert Button" href="#"  id='_bluesedulea' className="btn btn-blue" style={{width:'193.29px',fontSize:'0.625rem'}}>Schedule A Demo</a>
                                      </p>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<div className="item id4">
      
        <section id="aesthetic">
          <div  className="aestheticdoc">
            <div className="container doccontainer">
              <div className="row cnt1">
                <div className="col-md-7 col-md-offset-5 _doccol">
                  
                    <div id="stack_4FD8FFC758935CC7F85A8D14D7984EE7">
                      <div className="items cnt1">
                        <div className="item id1 first last">
                          <div className="ff inline type_htmltext id_html guid_306_057C50DECA65DEB266A4E71B489012B5">
                            <h2 className='whyuseh2'>Why choose Pro 1?</h2>
                            <p className='_whyp'>
                              We are a cutting edge Canada based
                              manufacturer and supplier of aesthetic,
                              body contouring and laser hair removal
                              equipment. Combining
                            </p>
                            <p className='_whyp'>
                              extensive expertise, Pro 1 Laser
                              produces high specification equipment
                              with a reputation for unrivalled
                              quality, reliability and post-purchase
                              support.
                            </p>
                            <h3 className='whath3'>
                              What makes Pro 1 unique and the best
                              partner in helping you introduce new
                              aesthetic technology to your business?
                            </h3>
                            <p className='_whyp'>
                              We understand that when using aesthetic
                              technology, our customers need to be
                              sure of its quality, reliability and
                              safety. With Pro 1 Laser you can rest
                              assured that we are there by your side
                              to assist you in every aspect of your
                              aesthetic business including purchase,
                              training, marketing and on-going
                              support.
                            </p>
                            <p className='_whyp'>
                              What makes Pro 1 Laser stand out is our
                              continued commitment to all our
                              customers, old and new. Our goal is to
                              build and support hundreds of aesthetic
                              practices across Canada.
                            </p>
                            <p className="centerMobile">
                              <a
                                data-track="link | Click | Home Page - Benefits &amp; Services Button Button"
                                href="#"
                                className="btn btn-white"  id="btnwhitlearn" style={{width:'9.404rem',fontSize:'0.75em'}}
                              >
                                Learn More
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </div>
    <div className="item id5">
    <section id="devices">
    <div  className="devicwt">
    <div className="container devicecont">
      <div className='row devicerow'>
      <div className="deviceilist">
        <div className='device1'>
        <h2 className='deviceheadinh'>Our Devices</h2>
        </div>
        <div className='device2'>
          <section id="products">
            <div className='container productdevicon'>
              <div className='row productdevrow'>
              <div className="col-sm-6 col-md-4 producdevice1col">
                <div className='productHover'>
              <div className="productHoverBorder"></div>
              <p className="prodImage">
                            <img src={product1img } alt="bliss max" width="236" height="397" />
                                        </p>
                                        <h3  className="_luxaft" >
                                          <strong>PRO LUX AFT</strong>
                                        </h3>
                                        <p className="_Aftstand">
                                          AFT stands for Advanced 
                                          Fluorescence Technology, and IPL 
                                          stands for Intense Pulsed Light.
                                        </p>
                                        <p className="viewProd">
                                          <a data-track="link | Click | Device Grid - Venus Versa" href=""   onClick={handleViewProductClick} className="btn btn-light" id="btnlight" style={{width:'10.521rem',fontSize:'0.75em'}}>
                                            View Product
                                          </a>
                                        </p>
                                        </div>

              </div>
              <div className="col-sm-6 col-md-4 producdevice1col">
                <div className='productHover'>
              <div className="productHoverBorder"></div>
              <p className="prodImage">
                            <img src={product2img} alt="bliss max" width="236" height="397" />
                                        </p>
                                        <h3  className="_luxaft" >
                                          <strong>PRO LUX AFT</strong>
                                        </h3>
                                        <p className="_Aftstand">
                                        Emshape machine is for muscle building
                                         and body slimming.
                                        </p>
                                        <p className="viewProd">
                                          <a onClick={() => history("/emphase")} data-track="link | Click | Device Grid - Venus Versa" href="" className="btn btn-light" id="btnlight" style={{width:'10.521rem',fontSize:'0.75em'}}>
                                            View Product
                                          </a>
                                        </p>
                                        </div>

              </div>
              <div className="col-sm-6 col-md-4 producdevice1col">
                <div className='productHover'>
              <div className="productHoverBorder"></div>
              <p className="prodImage">
                            <img src={product3img} alt="bliss max" width="236" height="397" />
                                        </p>
                                        <h3  className="_luxaft" >
                                          <strong>PRO SCULPT</strong>
                                        </h3>
                                        <p className="_Aftstand">
                                        1060nm Diode Laser system adopt the
                                         non-invasive body
                                        </p>
                                        <p className="viewProd">
                                          <a onClick={() => history("/sculpt")} data-track="link | Click | Device Grid - Venus Versa" href="" className="btn btn-light" id="btnlight" style={{width:'10.521rem',fontSize:'0.75em'}}>
                                            View Product
                                          </a>
                                        </p>
                                        </div>

              </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>

    </div>

    </div>
    </section>
    </div>
    <div className="item id6">
    <section id="speaktoexpert">
    <div className='speaktoexpertdiv'>
      <div className='container spaekcont'>
      <div className="row rowspeak">
      <div className="col-md-8 col-md-offset-2 colspeak">
        <div className='_textal'>
        <div className="twldldll">
                            <h2 className='speakh2'>Speak To An Expert</h2>
                            <p className='bluesedulep'>
                              To book a consultation or a demo, please
                              contact us by clicking the button below
                              and a representative will reach out to
                              you directly.
                            </p>
                            <p>
                              <a aria-label="Opens the contact dialog" data-track="link | Click | Speak to an Expert Slice Button" href="#" className="btn btn-blue" id="btncontactus" style={{width:'9.303rem',fontSize:'0.75em'}}>Contact Us</a>
                            </p>
                          </div>
        </div>
      </div>

      </div>
        </div> 
    </div>
    </section>
    <section id="bradnproduct">
    
      <div className='conatiner bradnproductdiv'>
        <div className='row bradnproductrow'>
          <div className="col-12 col-md-6 col-lg-3 bradnproductcol">
          <img className="group_img" src={brandproduct1img} alt="Group 1" height="300px" width="350px" />
          </div>
          <div className="col-12 col-md-6 col-lg-3 bradnproductcol">
          <img className="group_img" src={brandproduct2img} alt="Group 1" height="300px" width="350px" />
          </div>
          <div className="col-12 col-md-6 col-lg-3 bradnproductcol">
          <img className="group_img" src={brandproduct3img} alt="Group 1" height="300px" width="350px" />
          </div>
          <div className="col-12 col-md-6 col-lg-3 bradnproductcol">
          <img className="group_img" src={brandproduct1img} alt="Group 1" height="300px" width="350px" />
          </div>
          
        </div>
      </div>
      
    </section>
    <section id="pampalte" >
      <div className="container featurecontain">
        <div className="rows paplaterow">
          <div className="col-12 col-md-6 paplatecol">
            <h2 className="feature-h2">
              The Most Powerful Technology for Radical Muscle Toning
            </h2>
            <p className="feature-p">Get ready for a unique experience. All over the world, professionals are experiencing a true revolution that has changed the way of seeing body shaping and beauty, it has gone from the old located fat treatment to modem mode building and fat burning systems with technologies previously used only in elite sports</p>
          </div>
          <div className="col-12 col-md-6 best-imagecol">
            <img className="best-image" src={pamplateimg} alt="Feature" />
          </div>
        </div>
      </div>
    </section>

    </div>
    <div className="newbg_contact">
    <div className="container newbgcontactcontain">
    <div className="row contrr444 text-center">
          <h2 className='contrr4h2'>Contact our Support & Sales Team</h2>
          <p className='contrr44p' >
            Beauty products Suppliers, Schools, Aesthetic Institutes or others interested in tie-up (partner) with PRO 1 LASER.
          </p>
          <ul className="contrrulll">
            <li className="contr44li" >Business Association</li>
            <li className="hidden-xs contr44xs">I</li>
            <li  className="contr44li" >Device Demonstration</li>
            <li className="hidden-xs contr44xs" >I</li>
            <li  className="contr44li" >Treatment Packages</li>
          </ul>
        </div>
        <div className="row contactboxrow">
        <div className="col-md-12 text-center contactcol">
            <h2 className='fronth2'>PRO 1 LASER</h2>
            <p className='frontppp'>Frontrunner in Aesthetics Industry</p>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4 contctm444" align="center">
            <a href="#" className='bussinaaa' data-track="">
              Business Enquiry Call Us Now :
            </a>
          </div>
          <div className="col-md-3 contctm444" align="center">
            <a href="#" className='bussinaaa' data-track="">
              +1 (604) 649-1234
            </a>
          </div>
          <div className="col-md-3 lllll" ></div>
          
          <div className="col-md-1"></div>
          <div className="col-md-4 contactlogocontact"  >
            <img className='contactlogoimg' src={headimg} alt="Head" />{' '}
            <a href="#" className='contactlogoaaaa'  data-track="">
              Toll-Free +1 (604) 649-1234
            </a>
            <br />
            <img className='contactlogoimg' src={mailimg} alt="Mail" />{' '}
            <a href="#" className='contactlogoaaaa' data-track="">
              info@pro1laser.com
            </a>
          </div>
          <div className="col-md-4 contactlogocontact">
            <img className='contactlogoimg' src={loactionimg} alt="Location" />{' '}
            <a href="#" className='contactlogoaaaa' data-track="">
              3077 Woodbine Drive, North Vancouver
            </a>
            <br />
            <img className='contactlogoimg' src={callimg} alt="Call" />{' '}
            <a href="#" className='contactlogoaaaa' data-track="">
              +1 (604) 649-1234
            </a>
          </div>
  
          <div className="col-md-3 contactlogocontact">
            <img className='contactlogoimg' src={loactionimg} alt="Location" />{' '}
            <a href="#" className='contactlogoaaaa'  data-track="">
              BC V7R 2S3 Canada
            </a>
            <br />
            <img className='contactlogoimg' src={callimg} alt="call" />{' '}
            <a href="#" className='contactlogoaaaa'  data-track="">
              +1 (604) 649-1234
            </a>
          </div>
        </div>

    </div>
    
    </div>
    <div className='footbackgrounddiv'></div>
    {/* <div className='footbackgrounddiv'></div>
    <footer className="footer actualfooterdiv">
    <div className="container pb-30 footcont">
    <img src={footerlogo} className='footerlogimg' alt="NewsMe Logo" />
    </div>
    <div className="container footcot2" >
    <div className="row">
          <div className="col-lg-4 col-sm-4 col-xs-12">
          <div className="single_footer">
          <h4 className="singlefooterh4">WHY PRO1LASER CONCEPT?
            </h4>
            <span className='singlefooterafter'>-</span>
          <ul className="singlefooteulll">
                <li><a href="#" data-track="">Benefits & Services</a></li>
                <li><a href="#" data-track="">Medical Devices</a></li>
                <li><a href="#" data-track="">Aesthetic Devices</a></li>
                <li><a href="#" data-track="">News Events</a></li>
                <li><a href="#" data-track="">Investor Relation</a></li>
              </ul>
          </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer">
              <h4 className="singlefooterh4">Page Link
              </h4>
              <span className='singlefooterafter'>-</span>
              <ul className="singlefooteulll">
                <li><a href="#" data-track="">About</a></li>
                <li><a href="#" data-track="">Blog</a></li>
                <li><a href="#" data-track="">Products</a></li>
                <li><a href="#" data-track="">Contact</a></li>
                <li><a href="#" data-track="">Careers</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer">
              <h4 className="singlefooterh4">Subscribe today</h4>
              <span className='singlefooterafter'>-</span>
              <div className="signup_form">
                <form action="#" className="subscribeform">
                  <input type="text" className="subscribeinput" placeholder="Enter Email Address" />
                  <button type="button" className="subscribe__btn">
                    <i className="fas fa-paper-plane suscribeicon"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="social_profile">
              <ul className="socialprofileul">
                <li><a href="#" data-track=""><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#" data-track=""><i className="fab fa-twitter"></i></a></li>
                <li><a href="#" data-track=""><i className="fab fa-google-plus-g"></i></a></li>
                <li><a href="#" data-track=""><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          </div>
    </div>
    </footer> */}
</div>
    </StyledText>

);
};

export default Home;






