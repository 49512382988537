import React, { useState, useEffect } from 'react';

import "./Aftproduct.css";
import altimg  from "./images/aft.png";
import styled from 'styled-components';
import product1img from "./images/ne.jpg";
import product1imgbgless from "./images/neremovebg.png";
import AftImg from "./images/sculpcomimg.jpg";
import fatreduimg from './images/max-pro_04.png'
import aftsafe from "./images/aftsave.jpg";
import dolar from "./images/planning_bg.jpg";
import { Collapse } from 'react-bootstrap';
import aft2imgggg from "./images/deviceafte.jpg"
import componentimg from "./images/artas-ix_grid.png"


const StyledText = styled.p`
  font-family: 'Raleway', sans-serif;
  
`;


const Aftprodut = () => {
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [openCollapse3, setOpenCollapse3] = useState(false);
  const [openCollapse4, setOpenCollapse4] = useState(false);

  const handleToggleCollapse1 = () => {
    setOpenCollapse1(!openCollapse1);
  };

  const handleToggleCollapse2 = () => {
    setOpenCollapse2(!openCollapse2);
  };

  const handleToggleCollapse3 = () => {
    setOpenCollapse3(!openCollapse3);
  };

  const handleToggleCollapse4 = () => {
    setOpenCollapse4(!openCollapse4);
  };
  
  useEffect(() => {
    const subnav = document.querySelector('#atfsubNavslice');
    const subnavTopOffset = subnav.offsetTop;

    const handleScroll = () => {
      if (window.scrollY >= subnavTopOffset) {
        subnav.classList.add('sticky-headed');
      } else {
        subnav.classList.remove('sticky-headed');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <>
     <StyledText>
    <div className='afusleeindiv'></div>
    <div className='aftmaoincontentbox'>
      <div className='aftcontent'>
        <div className='row'>
          <div >
            <div className='contents_guid_'>
              <div className='aftitem1'>
                <section>
                  <div className='container aftfirstcontainer'>
                    <div className='row aft1infodiv'>
                    <div className="col-12 col-lg-8 aft1infodivcol">
                    <div className="aft1infconttttt">
                    <div className="aft1infco111111">
                    <p className="aft1infppppp">
                    <h3 className="_produvctname"><strong>PRO LUX AFT</strong></h3>
                      {/* <img className="aft1img" src={altimg} alt="Venus VBliss Max Logo" width="354" height="176" /> */}
                      </p>
                  <h1 className="aft1infh1111">Pro Lux Aft Laser is the next generation of IPL laser treatment technology.</h1>
                  {/* <h2  className="aft1infh2222">AFT  improves texture, tone, appearance and age spot removal of the skin with minimal downtime.</h2> */}
                  <p className="aft1infh1ppp">AFT uses low energy light to gently heat and reduce skin imperfections. This unique “Equal Distribution” technique, varies from a traditional IPL treatment. Most lasers only produce a single wavelength of light and limits on what they can effectively treat. AFT, however, emits a particular type of light, transmitting energy pulses over many wavelengths. This allows the light to penetrate the skin. Our unique spectrum filtering system eliminates hot spots. This allows the AFT treatment to produce a more powerful yet safe and effective treatment.</p>
                  <p className="aftbuttfppppp">
                    <a  href="#" className="spaekbutton">Speak to an expert</a></p>
                    </div>
                    </div>
                    </div>
                    <div className="col-12 col-lg-4 aft1infodivimgcol2">
                    <div className='aft1infodivimg'>
<p className='aft1infodivimgpppp'><img className='aft1infodivimgsrc' src={product1img } alt="product header"  /></p>

<p className='onluppp'></p>
</div>
                    </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="itemid2">
      <div className="ff inline type_stack id_subNavslice guid_400_E949C6AD83EB47E7AD3297BC469D370B widget_bootstrapslice">
        <div  className="sticky-subnav alfsubnav">
          <section id="atfsubNavslice">
            <div className="container atfcontnsubnav">
              <div className="row atfcontnsubnavrow">
                <div className="col-12 col-lg-7 atfcontnsubnavrcol1">
                  <div className="">
                    <div >
                      <div className="items cnt1">
                        <div className="item id1 first last">
                          <div className="ff inline type_htmltext id_html guid_306_ADB6426B793340C9B8E50F191296E892">
                            <div id="htmltext_6EF0C4A829F9643FE4C588C4DA95BA08">
                              <div className="wrapper atfsubnavcontent">
                                <a aria-label="Scrolls down to Venus Bliss Max section" data-track="link | Click | alfpage - Venus Bliss Max" href="" className="smooth-scroll atfsubnavcontentaaaaa">PRO  Lux Aft</a>&nbsp;&nbsp;
                                <a aria-label="Scrolls down to Key Features section" data-track="link | Click | Bliss Page - Key Features" href="" className="smooth-scroll atfsubnavcontentaaaaa">BENEFITS</a> 
                                <a aria-label="Scrolls down to Modalities section" data-track="link | Click | Bliss Page - Modalities" href="#" className="smooth-scroll atfsubnavcontentaaaaa">METHOD</a> 
                                <a aria-label="Scrolls down to Treatments section" data-track="link | Click | Bliss Page - Treatments" href="#" className="smooth-scroll atfsubnavcontentaaaaa">ANALYSIS</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 atfcontnsubnavrcol2222">
                  <div className="">
                    <div id="">
                      <div className="items cnt1">
                        <div className="item id1 first last">
                          <div className="ff inline type_htmltext id_htmlen-gl guid_306_C35734437E8F434A817ABD86DF92AB37">
                            <div id="htmltext_67E4CFD9B2BDC49447A4023B439E3E7D">
                              <div className='atfsubnavcontent'>
                                <a aria-label="Opens the contact dialog" data-track="link | Click | Bliss Page - Speak to an Expert Button" href="#" className="speakbuttonnavbar">Speak to an expert</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div className="itemid3">
    <div className="">
        <div className="flex-row atfcontet3div">
          <section >
            <div className="container atfcontet3contain">
              <div className="row atfcontet3row">
                <div className="col-md-12 atfcontet3col">
                  <div className="atfcontenttttt">
                    <div >
                      <h2 className='atfcontenh222'>Pro Lux Aft Laser Provide  many benefit</h2>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 atfcontet3col">
                  <div className="">
                    <div >
                      <div >
                        <div >
                          <div >
                            <div  className="atfcontet3content2">
                              <p className='atfcontet3contentpppp'>
                                <img className='atfcontet3contentimgg' src={ aft2imgggg }  /></p>
                              <h4 className='atfcontet3h4444'><strong className='atfcontet3h4ppp'>HANDPICES</strong></h4>
                              <p className='atfcontet3ppp'>Each unit comes equipped with 300,000 pulses (150,000/handpiece).</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 atfcontet3col">
                  <div className="">
                    <div >
                      <div >
                        <div >
                          <div >
                            <div className="atfcontet3content2">
                              <p className='atfcontet3contentpppp'>
                              <img className='atfcontet3contentimgg' src={AftImg}  /></p>
                              <h4 className='atfcontet3h4444'><strong className='atfcontet3h4ppp'>Connected handpieces</strong></h4>
                              <p className='atfcontet3ppp'>No need to swap handpieces, always connected.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 atfcontet3col">
                  <div className="">
                    <div >
                      <div >
                        <div >
                          <div >
                            <div  className="atfcontet3content2">
                              <p className='atfcontet3contentpppp'>
                                <img className='atfcontet3contentimgg' src={aftsafe }  /></p>
                              <h4 className='atfcontet3h4444'><strong className='atfcontet3h4ppp'>ADJUST TEMPRATURE</strong></h4>
                              <p className='atfcontet3ppp'>Built-in, adjustable contact cooling.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 atfcontet3col">
                  <div className="">
                    <div >
                      <div >
                        <div >
                          <div >
                            <div  className="atfcontet3content2">
                              <p className='atfcontet3contentpppp'>
                                <img className='atfcontet3contentimgg' src={ dolar}  /></p>
                              <h4 className='atfcontet3h4444'><strong className='atfcontet3h4ppp'>CONDITION</strong></h4>
                              <p className='atfcontet3ppp'>Over 30 treatable conditions!.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
    
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div className="item id4">
      <div className="black-bg text-white aftkeyfeatures">
        <section>
          <div className="container aftkeyfeaturescontainer">
            <div className="row aftkeyfeaturow">
              <div className="col-12 col-lg-5 aftkeyfeatcol1">
                <div>
                  <div>
                    <div className="items cnt1">
                      <div className="item id1 first last">
                        <div className="ff inline type_htmltext id_html guid_306_0C3613D1CA374898A955BF224030135C">
                          <div id="htmltext_AB68A0A335F59C7AC678B6B1F872E4BC">
                            <p className="aftkeyfeatpppp">
                              <img src={product1imgbgless} className="aftkeyfeaimg" alt="applicator specs"  />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 aftkeyfeatcol222">
                <div>
                  <div className="aftkeyfeatcol2divv">
                    <div className="items cnt2">
                      <div className="item id1 first">
                        <div>
                          <div>
                            <h2 className='aftkeyfeatcol2h222'>Key Features of Pro Lux Aft</h2>
                            <p className='aftkeyfeatcol2pppppp'>AFT stands for Advanced Fluorescent Technology. AFT Laser is the next generation of IPL laser treatment technology. This technology improves texture, tone, appearance and age spot removal of the skin with minimal downtime.&nbsp;<span className="Apple-converted-space"><br /></span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item id2 last">
                  <div className="aftkeyfeatdropdown1up">
                    <div id="bootstrapcollapse_11E53C2E0D313F02A9E56DFD9769209F" className="features-collapse">
                      <section>
                        <div className="aftfeatures-collapse" data-count="5">
                          {/* Panel 1 */}
                          <div className="aftfeaturescollapsedown">
                            <div className="aftdroppanelheading">
                              <h4 className="aftdroppaneltitle">
                                <a className='aftdroppanebtton'
                                  onClick={handleToggleCollapse1}
                                  data-parent="#bootstrapcollapse_459999D1B1D46DF7F565C921242D00F1"
                                  data-name="<span>1</span> Four 1064nm Diode Laser Applicators [en-gl]"
                                
                                  data-track=""
                                >
                                  <span className='aftdroppanspann'>1</span>  Skin Glow
                                </a>
                              </h4>
                            </div>  <Collapse in={openCollapse1}>
                            <div id="collapse1" className="panel-collapse collapse">
                              <div className="panel-body aftdroppanelbodyy">
                                <div className="">
                                  <div id="htmltext_BC953129095FA85898E82AE50B18348B">
                                    <p className="aftdroppanelbodypppp">
                                    When there is excess weight gain, more fat accumulates in the fat cells causing them to swell which results in cellulite. The EMShape interacts with muscle neurons to induce supramaximal muscle contraction in the process of continuous muscle contractions, part of myofibrillar are gradually broken to cause muscle self-repair.  
                                    Although improvements will be evident after one procedure, it is recommended that 2 to 4 laser sessions booked a month apart will provide maximum benefit. This is especially the case if you are targeting acne scar removal or brown aging spot
                                      <span className="Apple-converted-space">&nbsp;</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div></Collapse>
                          </div>
                          {/* Panel 2 */}
                          <div className="aftfeaturescollapsedown">
                            <div className="aftdroppanelheading">
                              <h4 className="aftdroppaneltitle">
                                <a className='aftdroppanebtton'
                                   onClick={handleToggleCollapse2}
                                  data-name="<span>2</span> Four FlexMAX EMS Applicators [en-gl]"
                                  href="#bootstrapcollapse_459999D1B1D46DF7F565C921242D00F1_2"
                                  data-track=""
                                >
                                  <span className='aftdroppanspann'>2</span> Skin Condition
                                </a>
                              </h4>
                            </div><Collapse in={openCollapse2}>
                            <div d="collapse2 " className="panel-collapse collapse">
                              <div className="panel-body aftdroppanelbodyy">
                                <div className="">
                                  <div id="htmltext_5F4920D03996E7760B211EB47F7F585B">
                                    <p className="aftdroppanelbodypppp">
                                    Low-energy light with many wavelengths is pulsed below the skin’s surface into the tissue below. IPL uses broadband pulsed light, and AFT laser technology uses fluorescent pulsed light. A special spectrum filtering system is incorporated to equally distribute the light and heat.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div></Collapse>
                          </div>
                          {/* Panel 3 */}
                          <div className="aftfeaturescollapsedown">
                            <div className="aftdroppanelheading">
                              <h4 className="aftdroppaneltitle">
                                <a className='aftdroppanebtton'
                                 onClick={handleToggleCollapse3}
                                  data-name="<span>3</span> Four FlexMAX EMS Applicators [en-gl]"
                                  href="#bootstrapcollapse_459999D1B1D46DF7F565C921242D00F1_2"
                                  data-track=""
                                >
                                  <span className='aftdroppanspann'>3</span> Laser Safe
                                </a>
                              </h4>
                            </div><Collapse in={openCollapse3}>
                            <div className="panel-collapse collapse">
                              <div className="panel-body aftdroppanelbodyy">
                                <div className="">
                                  <div id="htmltext_5F4920D03996E7760B211EB47F7F585B">
                                    <p className="aftdroppanelbodypppp">
                                    The laser beam heats up the skin cells below the surface, causing friction in the blood vessels and nerve endings. As these heal, the skin is left smoother with a better texture. The heat also kickstarts collagen to produce and rebuild the skin cells.
                                    Patients will feel a zap to their skin whenever the laser light is administered. A topical numbing agent lathered on the face prior to treatment will minimize any discomfort. Dr. Cory Torgerson also advises that Tylenol or Advil be taken an hour ahead of the session. If you are nervous about the pain level, freezing the face is possible, if necessary.
                                      <span className="Apple-converted-space">&nbsp;</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div></Collapse>
                          </div>
                          {/* Panel 4 */}
                          <div className="aftfeaturescollapsedown">
                            <div className="aftdroppanelheading">
                              <h4 className="aftdroppaneltitle">
                                <a className='aftdroppanebtton'
                                  onClick={handleToggleCollapse4}
                                  data-name="<span>4</span> Four FlexMAX EMS Applicators [en-gl]"
                                  href="#bootstrapcollapse_459999D1B1D46DF7F565C921242D00F1_2"
                                  data-track=""
                                >
                                  <span className='aftdroppanspann'>4</span> REcovery Time
                                </a>
                              </h4>
                            </div><Collapse in={openCollapse4}>
                            <div className="panel-collapse collapse">
                              <div className="panel-body aftdroppanelbodyy">
                                <div className="">
                                  <div id="htmltext_5F4920D03996E7760B211EB47F7F585B">
                                    <p className="aftdroppanelbodypppp">
                                    AFT is considered non-invasive, so there is no need to take time off of work. Your skin may feel a little tight after treatment, and it may look slightly pink for 2 to 3 days.mmediately following AFT laser therapy, you will notice that your skin is smoother and the texture has improved significantly. Because this type of treatment deals with the underlying skin cells and kickstarts collagen reproduction, you will notice continued improvement in the days and weeks following this procedure.
                                      <span className="Apple-converted-space">&nbsp;</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div></Collapse>
                          </div>
                        
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div className="item id6">
      <div className="ff inline type_stack id_modalitiesslice guid_400_E0169EF8384D4B27969ECC73B9BCC140 widget_bootstrapslice">
        <div className="atfmodalitiesdiv">
          <section id="atfmodalitiessection">
            <div className="container atfmodalitiessectcontai">
              <div className="row atfmodalitiessectcol1">
                <div className="col-md-12 first id1">
                  <div className="ff inline type_stack id_stack guid_312_CE102B36481E4C29B001B8689E09B8B0">
                    <div id="stack_52C09A45BB78B86EC67001D5D0A99FC5" className="atfmodalitiessectpadding5">
                      <div className="items cnt1">
                        <div className="item id1 first last">
                          <div className="ff inline type_htmltext id_htmlen-gl guid_306_8395C4C2549D4DB6BA66114F1C1A63F7">
                            <div id="htmltext_5DC83FF49F830C6BF441919FFE936782" className="text-center">
                              <div className="title-h6"></div>
                              <h2 className="atfmodalitiessech222">WE Provide</h2>
                              <p className="atfmodalitiesseppppp">Target fat, muscle, and skin effectively and comfortably. Maximize results and patient satisfaction by tailoring the right modality for each indication.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 id2 atfmodaliessectcol2222">
                  <div className="ff inline type_stack id_row1slice guid_400_068E510D82854747B1B079BE3A26BE54 widget_bootstrapslice">
                    <div id="bootstrapslice_40FCD3ED2777E47DBA58BDD57698F321">
                      <section id="row1slice">
                        <div className="container atfmodaliessectcontain2222">
                          <div className="row cnt2">
                            <div className="col-12 col-lg-6 first id1">
                              <div className="ff inline type_stack id_stack-image guid_312_03EB762C75634F14924F0049D0545563">
                                <div id="stack_F7FA0A2D85AC05D80F0DB0DDFB2CFF66">
                                  <div className="items cnt1">
                                    <div className="item id1 first last">
                                      <div className="ff inline type_htmltext id_html guid_306_34A67FF3B68B426294ACE6C203AC40F7">
                                        <div id="htmltext_8915F49E1801EBBC16A97698A066274C">
                                          <p><img src={AftImg} alt="vbl diode" width="564" height="400"   className='prodyimhhhhmdkd'/></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-6 last id2">
                              <div className="ff inline type_stack id_stack-text guid_312_DB19C3DB7DA54D0C8C507E044A5AC56D">
                                <div id="stack_FB7E79729B6BF43CB22B4724734F74F5">
                                  <div className="items cnt1">
                                    <div className="item id1 first last">
                                      <div className="ff inline type_htmltext id_htmlen-gl guid_306_B9D3DA11C4C449EF91C785C31EC858A1">
                                        <div id="htmltext_D4DA9F4E4D5513876D684E8F783C1110">
                                        <h3>Each unit comes equipped with 300,000 pulses (150,000/handpiece).</h3>
                                          <p className="p1"><strong><i>Over 30 treatable conditions!</i></strong></p>
                                          <hr />
                                          <p className="p1" style={{ textAlign: 'justify' }}> IT is next generation technology. This technology improves texture, tone, appearance and age spot removal of the skin with minimal downtime.

It uses low energy light to gently heat and reduce skin imperfections. This unique “Equal Distribution” technique, varies from a traditional IPL treatment. Most lasers only produce a single wavelength of light and limits on what they can effectively treat. , however, emits a particular type of light, transmitting energy pulses over many wavelengths. This allows the light to penetrate the skin. Our unique spectrum filtering system eliminates hot spots. This allows the AFT treatment to produce a more powerful yet safe and effectiv<span className="Apple-converted-space">&nbsp;</span></p>
                                          <ul>
                                            <li style={{ textAlign: 'justify' }}>No need to swap handpieces, always connected.</li>
                                            <li style={{ textAlign: 'justify' }}>Over 30 treatable condition</li>
                                            <li style={{ textAlign: 'justify' }}>Advanced cooling technology: skin-contact asfe and secure</li>
                                            <li style={{ textAlign: 'justify' }}>clinic will recommend a treatment package of 3 to 4 sessions spaced approximately 4 weeks apart for optimal results.</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 id3">
                  <div className="ff inline type_stack id_row2slice guid_400_023F63537BCB47EE8A4717F43239C0A5 widget_bootstrapslice">
                    <div id="bootstrapslice_003AA1603764E461DB10EDF5D4A6D0F6">
                      <section id="row2slice">
                        <div className="container">
                          <div className="row cnt2">
                            <div className="col-md-6 first id1">
                              <div className="ff inline type_stack id_stack-text guid_312_C3D36FFD88F84FDAA14B55A88B7F52FB">
                                <div id="stack_09DAFBB5A95D050FBC8A3384DFB7AD79">
                                  <div className="items cnt1">
                                    <div className="item id1 first last">
                                      <div className="ff inline type_htmltext id_htmlen-gl guid_306_5D88DF45C3CB4895BA91BDAE369C184E">
                                        <div id="htmltext_C9A7215E58104782F97B87CEA714AA86">
                                          <h3>Adaptive FlexMAX EMS Machine Technology</h3>
                                          <p className="p1"><strong><i>Best-In-Class Electrical Muscle Stimulation Machine</i></strong></p>
                                          <hr />
                                          <p style={{ textAlign: 'justify' }}>The FlexMAX EMS modality offers an unprecedented solution to the muscle category. Controlled and precise energies are delivered uniquely to muscle groups via best-in-class electrical muscle stimulation. The muscles are safely activated without heating the surrounding tissue. Paired with specific pulse durations by muscle group, performance-informed smart training programs and advanced adaptive algorithms, each treatment is fully personalized and mimics a real training session that can build intensity over time. FlexMAX EMS enables effective and comfortable treatment programs regardless of the patients’ fitness level, resulting in stronger and more defined muscle appearance.<span className="Apple-converted-space">&nbsp;</span></p>
                                          <ul>
                                            <li>Hassle-free, hands-free operation: Easy-to-use Velcro belts and conductive gel application</li>
                                            <li>Adaptive Mode
                                              <ul>
                                                <li style={{ textAlign: 'justify' }}>AdapTarget:&nbsp;7 Targeted Muscle Groups<span className="Apple-converted-space">&nbsp;→&nbsp;</span>Personalization<span className="Apple-converted-space">&nbsp;</span></li>
                                                <li style={{ textAlign: 'justify' }}>AdapTrain:&nbsp;Smart Training Programs&nbsp;<span className="Apple-converted-space"><span className="Apple-converted-space">→&nbsp;</span></span>Efficiency &amp; Efficacy<i><span className="Apple-converted-space">&nbsp;</span></i></li>
                                                <li style={{ textAlign: 'justify' }}>AdapTensity:<span className="Apple-converted-space"><span className="Apple-converted-space">&nbsp;</span></span>Adaptive Parameters<span className="Apple-converted-space">&nbsp;→&nbsp;</span>Comfort &amp; Safety</li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 last id2">
                              <div className="ff inline type_stack id_stack-image guid_312_95968E6242BA464A95B09FADD17273A8">
                                <div id="stack_DD845A263018A37E1E75FEA93113DEC0">
                                  <div className="items cnt1">
                                    <div className="item id1 first last">
                                      <div className="ff inline type_htmltext id_html guid_306_1BD286FF84E64D72911FD6C9A42B0E7A">
                                        <div id="htmltext_C66665DF2D4663E8A9985B4C627C28B1">
                                          <p><img src="/img/bliss_max/flwx_max_rep22.png" alt="vbl diode" width="1081" height="1273" /></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>
      
     
    </div>
    </StyledText>
    </>
  )
}

export default Aftprodut
