
import React from 'react';
import { useState,useEffect } from 'react';
import "./Header.css";
import "./Product.css"
import Facbook from "./images/facebook (1).png";
import Google_plus from "./images/google-plus.png";
import Twitter from "./images/twitter2.png";
import vimbo from "./images/vimeo.png"
import Tumblr from "./images/tumblr.png"
import Search from "./images/Search.png";
import globe from "./images/gl.png";
import newsme from "./images/newsme.png";
import getfitlogo from"./images/getfitl.png"
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import fatremoveimg from "./images/fatremove.png";
import { useLocation ,useNavigate } from 'react-router-dom';
import { Button, AppBar, Toolbar, IPButton, Typography, Container } from '@material-ui/core';
import Product from './Product';  
import "./Commom&git.css";
import "./responsive&gitf.css";
import "./Skincar&git.css";
import "./Searchbox&git.css";
import "./Accessbil&git.css";
import "./Getfit.css";
import "./Strandard.css";
import "./Bootstrap&git.css";
import closewhite from "./images/closewhite.png"
const StyledText = styled.p`
  font-family: 'Raleway', sans-serif;
  
`;



const Header = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  window.addEventListener('resize', () => {
    setIsMobileView(window.innerWidth <= 768);
    setIsMobileView(window.innerWidth <= 1024);
    
     // Adjust the breakpoint as needed
  });
  useEffect(() => {
    // Check the screen width on initial load
    setIsMobileView(window.innerWidth <= 1180 ); // Adjust the breakpoint as needed
  }, []);

  // Add a listener to check the screen width when it changes
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 1180); // Adjust the breakpoint as needed
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggleRegionList = () => {
    setIsOpen(!isOpen);
  };

  const closeRegionList = () => {
    setIsOpen(false);
  };
 
  const history = useNavigate();
    const [regionListVisible, setRegionListVisible] = useState(false);

const [dropproduct,setDropProduct]=useState(false);
const [productDropdownVisible, setProductDropdownVisible] = useState(false);

  const toggleProductDropdown = () => {
    setProductDropdownVisible(!productDropdownVisible);
  };
  const handleHomeClick = () => {
  // Close the Products dropdown when Home is clicked
  setProductDropdownVisible(false);
  history("/");
};

const handleBlogClick = () => {
  // Close the Products dropdown when Blog is clicked
  setProductDropdownVisible(false);
  history("/blog");
};

const handleContactClick = () => {
  // Close the Products dropdown when Contact is clicked
  setProductDropdownVisible(false);
  history("/contact");
};
const closeProductDropdown = () => {
  setProductDropdownVisible(false);
};
  const [searchOpen, setSearchOpen] = useState(false);

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

 
  useEffect(() => {
    const logoSlice = document.querySelector('.logoSlice');
    
    const handleScroll = () => {
      if (window.scrollY > 0) {
        logoSlice.classList.add('stickyLogoSlice');
      } else {
        logoSlice.classList.remove('stickyLogoSlice');
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const location = useLocation();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    closeMenu();
  }, [location.pathname]);

  return (
    <header>
     <div id="header">
      <div
        class="ff inline type_stack id_header guid_312_238809A03F44827FB83D7BF5008F0795 shared"
      >
        <div class="items cnt4 headerStack headerARIA">
        <div className="item id1 first">
      <div className="ff inline type_htmltext id_html guid_306_D021548EBEB942A497958659853D9FEC">
        <div id="htmltext_5C6915BD9E43DFD702839D525B819E02" className="screen-reader-text">
          <p>
            <a aria-label="Click to go to the contact page" href="/en-us/contact-us.htm">Contact us</a>
          </p>
        </div>
      </div>
    </div>
    <div className="item id2">
      <div className="ff inline type_stack id_bootstrapsliceMiniGrey guid_400_A1049F3BE382D186E2EE826676D87DCF widget_bootstrapslice">
        <div id="bootstrapslice_E0AEB25016E8B503702B4849058C88DE" className="mini-grey-slice">
          <section id="bootstrapsliceMiniGrey">
            <div className="container-fluid">
              <div className="row cnt2">
                <div className="col-sm-5 first id1">
                  <div className="ff inline type_stack id_stack-columnleft guid_312_A2362DB5E382D186E2E4AF92C8CCD9E8">
                    <div id="stack_4445AF19AC8F6ADAB6DC7D2DF8743666" className="search-container">
                      <div className="items cnt2">
                        <div className="item id1 first">
                          <div className="ff inline type_htmltext id_html-phone guid_306_A23ECDEFE382D186E2EA5FC8AE710F49">
                            <div id="htmltext_516401DE7D9A8F93B040E69126CE9BD2">
                              <p>
                                TEL:
                                <a className="top-of-page" href="tel:1-888-907-0115" data-track="Phone | Click | Header - Phone number">+1 (604) 649-1234</a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="item id2 last">
                          <div className="ff inline type_htmltext id_searchbox guid_306_B15BDE4669C4415F99CB7855A65DF765">
                            <div id="htmltext_30B964C204EA4C00FEF53CE629CC4AE8">
                              <div className="searchWrapper-variantA">
                                <button className="sr-only searchPanelOpen">Opens the search panel</button>
                                <img src={Search} alt="" onClick={toggleSearch} />
                                <label htmlFor="search" className="sr-only">Search</label>
                                <input id="search"  className={`${searchOpen ? 'searchBoxOpen' : 'searchBox'}`} placeholder="Search" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 last id2">
                  <div className="ff inline type_stack id_stack-columnright guid_312_A234BD2EE382D186E2E0970799BFE481">
                    <div id="stack_E8BF0D0EE52A1E123B350B03E4AFAEB2">
                      <div className="items cnt1">
                        <div className="item id1 first last">
                          <div className="ff inline type_htmltext id_htmlen-gl guid_306_A24EC3C7E382D186E2EA437B3D6AB6EF">
                            <div id="htmltext_0C6FC6559FBB619F526ED403DC1600AC" className="top-utility-nav">
                            <div className="btn-group btn-group-padded">
      <button
        aria-label="Opens the region select dialog"
        id="selectLang"
        className="selectLang dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen} // Set aria-expanded based on the state
        onClick={toggleRegionList} // Toggle the region list on click
      >
        SELECT A REGION
      </button>
      {isOpen && ( // Render the region list only if isOpen is true
        <ul id="regionlist" className="dropdown-menu" style={{display:'block'}}>
          <li className="current">
            {/* <a href="#">Global</a> */}
            <p className="region-select-description" style={{color:'white'}}>
              Pro1Laser is a leader in the global medical and aesthetic device market, pairing best-in-class technology with the first and only true subscription model in the industry.
            </p>
          </li>
          <li className="nearest loading">
            <a href="#">Loading...</a>
          </li>
          <li className="all-regions">
            <a href="#" className='usalfl'>USA</a>
            <a href="#" className='hongkongfl'>Hong Kong</a>
          </li>
          <li>
            <button className="sr-only" id="closeSelectLang" onClick={closeRegionList}>
              Close Region Menu
            </button>
          </li>
        </ul>
      )}
    </div>
                              <div className="btn-group btn-group-padded">
                                <a className="xselectLang" href="#" target="_blank">
                                  INTERESTED IN A TREATMENT?
                                </a>
                              </div>
                              <div className="btn-group btn-group-padded">
                                <div className="top-social-wrapper">
                                  <div className="social-icon">
                                    <a href="#" target="_blank">
                                      <img width="16" height="16" src="https://pro1laser.com/wp-content/themes/skinbeauty/images/light/social-icon/facebook.png" alt="Facebook" />
                                    </a>
                                  </div>
                                  <div className="social-icon">
                                    <a href="#" target="_blank">
                                      <img width="16" height="16" src="https://pro1laser.com/wp-content/themes/skinbeauty/images/light/social-icon/google-plus.png" alt="Google Plus" />
                                    </a>
                                  </div>
                                  <div className="social-icon">
                                    <a href="#" target="_blank">
                                      <img width="16" height="16" src="https://pro1laser.com/wp-content/themes/skinbeauty/images/light/social-icon/tumblr.png" alt="Tumblr" />
                                    </a>
                                  </div>
                                  <div className="social-icon">
                                    <a href="#" target="_blank">
                                      <img width="16" height="16" src="https://pro1laser.com/wp-content/themes/skinbeauty/images/light/social-icon/twitter.png" alt="Twitter" />
                                    </a>
                                  </div>
                                  <div className="social-icon">
                                    <a href="#" target="_blank">
                                      <img width="16" height="16" src="https://pro1laser.com/wp-content/themes/skinbeauty/images/light/social-icon/vimeo.png" alt="Vimeo" />
                                    </a>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div className="item id3">
    <div className="ff inline type_stack id_logoslice guid_400_D2EA45F5F71923A62674622287B09C12 widget_bootstrapslice">
        <section id="logoslice">
          <div id="bootstrapslice_2873E4387A3DF263E72AE7CF25EE06C8" className="logoSlice">
            

            <div className="container">
              <div className="row cnt2">
                <div className="col-xs-3 col-md-2 first id1">
                  <div className="ff inline type_stack id_column1 guid_312_946F2BB701652D55AE5F1A87752F668C">
                    <div id="stack_A84FA9E554683FC158F8490B4AC98109">
                      <div className="items cnt1">
                        <div className="item id1 first last">
                          <div className="ff inline type_htmltext id_html-logoen-glen-usen-cafr-ca guid_306_6100ABC1E382D186E2EA237DB6D880F5">
                            <div id="htmltext_F3C1901B142FA8A47861FD7675
                            5A40CC">
                              <p className="text-left-he">
                                <a data-track="link | Click | Home Page - Logo" href="/">
                                  <img src={newsme} alt="Venus Concept Logo, links to homepage" width="210" height="50" />
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-9 col-md-10 last id2">
                {isMobileView ? (        
                <div className="ff inline type_stack id_stack guid_312_D2FF21DDF4D495CB1F107EE0727D5850">
                    <div className="items cnt2">
                      <div className="item id1 first">
                        <div className="ff inline type_htmltext id_htmlen-gl guid_306_CE9E2A5CA31D786F94FD6070A7BD3C8D">
                          <div id="htmltext_F77FC8B70D5264CA11A9FBD8CA74A8A8" className="socialIconsTop">
                            <p className='subjkkbutton'>
                            <button
        aria-label="Open mobile menu"
        type="button"
        className="Menubttonhead"
        
        id="menuButton"
        onClick={toggleMenu}
      >
        MENU
      </button>
                            </p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : (
                  <div className="ff inline type_stack id_bootstrapnavbar guid_400_D3012481C87C1046C7F8DC830DF89A52 widget_bootstrapnavbar">
                    <nav id="bootstrapnavbar_40FA1AE1A5DA0A0D549FE7882BD3E2D0" className="navbar navbar-default">
                      <div className="container navcont11" >
                        <div className="navbar-header">
                          <button type="button" >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                          </button>
                        </div>
                        <div className="colla navbar-colla" >
                          <div className="ff inline type_menu id_bootstrapmenu guid_400_D3024099A30A45E26BEE2EDD20FA2EFC widget_bootstrapmenu shared ">
                            <ul className="navbar-nav ml-auto nav navbar-nav ">
                              <li className="dropdown first" data-active="0" data-item="1" data-level="1" data-liclen="7" data-rolesexclude data-rolesinclude="9ACD5484-9495-4759-A57E-3CC620DCADAE,9A98689E-4361-4F93-A17D-90642F8F9CF0,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,B6F94240-5E16-4A67-8639-E0A064264CE9,FDFE5B32-3D1E-4265-B054-0EA5F5E0F2E6,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9" data-type="node" id="bootstrapmenu-_location_path_medical_aesthetic_devices_htm">
                              <a  onClick={handleHomeClick} data-aclen="7" data-checked="1" href="#">
                                   Home
</a>
                              </li>
                              <li className="dropdown" data-active="0" data-item="28" data-level="1" data-liclen="6" data-rolesexclude data-rolesinclude="9ACD5484-9495-4759-A57E-3CC620DCADAE,9A98689E-4361-4F93-A17D-90642F8F9CF0,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,B6F94240-5E16-4A67-8639-E0A064264CE9,FDFE5B32-3D1E-4265-B054-0EA5F5E0F2E6,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9" data-type="node" id="bootstrapmenu-_location_path_treatments_htm">
                                <a onClick={toggleProductDropdown}   data-aclen="6" data-checked="1" href="#">
                                  Products
                                </a>{productDropdownVisible && <Product closeDropdown={closeProductDropdown} />}
                              </li>
                              <li className="dropdown" data-active="0" data-item="104" data-level="1" data-liclen="4" data-rolesexclude data-rolesinclude="9A98689E-4361-4F93-A17D-90642F8F9CF0,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9" data-type="node" id="bootstrapmenu-_benefits_and_services">
                                <a  onClick={handleBlogClick}  data-aclen="4" data-checked="1" href="#">
                                  Blog
                                </a>
                              </li>
                              <li className="dropdown" data-active="0" data-item="255" data-level="1" data-liclen="3" data-rolesexclude data-rolesinclude="F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED" data-type="node" id="bootstrapmenu-_location_path_about_us_htm-254">
                                <a onClick={handleHomeClick} data-aclen="3" data-checked="1" href="#">
                                  LipoLax
                                </a>
                              </li>
                              <li className="last" data-active="0" data-item="570" data-level="1" data-liclen="0" data-rolesexclude data-rolesinclude="304A1464-8003-4271-86A4-D1A920BA0ED7,9ACD5484-9495-4759-A57E-3CC620DCADAE,BBAB7262-6A52-4FBC-B279-B0548E07ED81,3856B259-E417-4198-95C5-E31CC82C1D6E,8E8E93EE-30F8-4A2D-A555-B3DFBFC16C7D,9A98689E-4361-4F93-A17D-90642F8F9CF0,86DEB894-BD6F-48DD-9CE8-3B0B36B7A1B7,9CB677C4-9522-4045-A5EF-B75EB924EFA5,F51D6A8E-67DB-42F0-B468-5811D3B4E57F,2983B1B2-5614-4482-8F2D-66D53201E1D2,3EF02D2F-3647-464B-9F3E-2127BD939942,5DF90728-192C-4177-8828-058A7B6CA580,0666FB8C-ABE4-492F-A00C-439C24CD3C79,6CE25D94-A86D-4E2D-8F61-F2CCD008B5AB,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,260BE46F-D65A-49A3-8AE3-B1E1C1B9A536,47E8602E-A9AF-4CF9-96C9-95F63621A030,2C571E9A-99B1-47E7-8CBD-A0C677C56B7B,3EED3E18-742B-4694-BE39-ECA6EF8FD355,153D1A85-8838-4BD6-9C80-7E034896AE32,B6F94240-5E16-4A67-8639-E0A064264CE9,AAEEA06C-AA2B-4D4D-A8C2-655B58A744F8,9DF8A12E-82C0-470B-8FB7-B53C6F7AB011,54F5E7EB-B32F-4BD0-9E98-9B09C79646DF,1FD71330-C5B2-4039-921E-469E5B5D1F4A,63C68E09-FA69-475A-BA9C-23A713DB7A14,D8A3C790-4D04-4E14-A759-D19A7E83EA19,D2E731FF-5003-46A0-BDE1-92E090EA5C75,7846420B-ACDD-489B-9B36-F2CD4625B8C5,A4E6DB47-FE2D-4B7F-BBD8-689D9AEC154E,BC0A496F-B5FA-47B4-996D-34CC08638CAD,59BC182B-B2A7-42D6-B29B-AFB86EC580DF,BA4707A8-A9F8-49BC-B35F-C49FE0DB3E3C,5261281A-558E-40C3-BD17-C641B51C8430,1E669D22-C1AC-4359-89EB-128C74C2A089,2F26154F-5B75-425B-AF01-91BB62318B87,6ECBDFE1-A567-482B-BE41-10E1C33F5016,E23755AA-B5CE-4C2F-9ED9-03C0E6E175B6,19B119FB-438C-4260-8B6E-CCB0480598A3,F6CBA56D-68A3-42BA-898C-B1A4EB43CEB0,0C8329D9-9FC1-4492-867C-A145D71F872C,59C07E69-0460-472E-B3B3-DAE68EECFB11,FAEAD31A-A01A-4EAB-866C-78ADC1BBB6BB,D3051C64-8A4D-4BA3-B896-204871A48F0F,FDFE5B32-3D1E-4265-B054-0EA5F5E0F2E6,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9,8EABEFFE-589C-494F-B4F8-174DD2BE1287" data-type="node" id="bootstrapmenu-_location_path_contact_us_htm-569">
                                <a  onClick={handleContactClick} data-aclen="0" data-checked="1" href="#">
                                 Contact
                                </a>
                              </li>
                            </ul>
                          </div>
                     
                          
                        </div>
                      </div>
                    </nav>
                  </div>
                   )}
                  
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </div>
      
    </div>
    {isMenuOpen && (
    <div className="item id4 last">
    <div className="widget_bootstrapslice">
      <div id="" className=" smallmenu fadeed modal">
        <section id="smallmenu">
          <div className="container">
            <div className="row cnt1">
              <div className="first last id1">
                <div className="ff inline type_stack id_stack guid_312_21897CC7F477770DDCFCE5D9E5535D0D">
                  <div id="stack_C0ABED5B2F35C4DDDEB3B4DC2A7DFC34" className="items cnt3">
                    <div className="item id1 first">
                      <div className="ff inline type_htmltext id_html guid_306_222689BFE6B153FD7C1E4E643E6CA059">
                        <div id="htmltext_6263A8E20EBA2EE5D0B8C428E2CDFAF8">
                          <p>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={closeMenu}>
                              CLOSE
                              <img src={closewhite} alt="exit button" />
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item id2">
                      <div className="ff inline type_htmltext id_rendersmallmenu guid_306_DFF89D35D5FD0BB25DEC6D02738E6C22">
                        <div className="renderSmallMenu">
                        <ul>
      <li className="dropdown first" data-active="0" data-item="1" data-level="1" data-liclen="7" data-rolesexclude="" data-rolesinclude="9ACD5484-9495-4759-A57E-3CC620DCADAE,9A98689E-4361-4F93-A17D-90642F8F9CF0,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,B6F94240-5E16-4A67-8639-E0A064264CE9,FDFE5B32-3D1E-4265-B054-0EA5F5E0F2E6,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9" data-type="node" id="bootstrapmenu-_location_path_medical_aesthetic_devices_htm_1">
      <a onClick={handleHomeClick}  data-aclen="7" data-checked="1" href="#">
                                   Home
</a>
      </li>
      <li className="dropdown" data-active="0" data-item="28" data-level="1" data-liclen="6" data-rolesexclude="" data-rolesinclude="9ACD5484-9495-4759-A57E-3CC620DCADAE,9A98689E-4361-4F93-A17D-90642F8F9CF0,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,B6F94240-5E16-4A67-8639-E0A064264CE9,FDFE5B32-3D1E-4265-B054-0EA5F5E0F2E6,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9" data-type="node" id="bootstrapmenu-_location_path_treatments_htm_1">
      <a onClick={toggleProductDropdown}   data-aclen="6" data-checked="1" href="#">
                                  Products
                                </a>{productDropdownVisible && <Product closeDropdown={closeProductDropdown} />}
      </li>
      <li className="dropdown" data-active="0" data-item="104" data-level="1" data-liclen="4" data-rolesexclude="" data-rolesinclude="9A98689E-4361-4F93-A17D-90642F8F9CF0,F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED,FDF53B62-85B6-4B1C-9EE9-3ED8C34200B9" data-type="node" id="bootstrapmenu-_benefits_and_services_1">
      <a   onClick={handleBlogClick} data-aclen="4" data-checked="1" href="#">
                                  Blog
                                </a>
      </li>
      <li className="dropdown" data-active="0" data-item="255" data-level="1" data-liclen="3" data-rolesexclude="" data-rolesinclude="F4687BF4-2B9B-41CD-AB01-7ACAAD0A3DED" data-type="node" id="bootstrapmenu-_location_path_about_us_htm-254_1">
      <a onClick={handleHomeClick} data-aclen="3" data-checked="1" href="#">
                                  LipoLax
                                </a>
      </li>
      <li className="last" data-active="0" data-item="570" data-level="1" data-liclen="0" data-rolesexclude="" >
      <a  onClick={handleContactClick} data-aclen="0" data-checked="1" href="#">
                                 Contact
                                </a>
      </li>
      </ul>


                        </div>
                      </div>
                    </div>
                    {/* <div className="item id3 last">
                      <div className="ff inline type_htmltext id_htmllinksen-gl guid_306_BC6A9B6FF52E4528ED34A200447E93A1">
                        <div id="htmltext_7DCB936820C506B4895369E400D0ACEB">
                          <div className="socialIconsTop">
                            <div className="btn-group" style={{display:'block'}}>
                              <a className="selectLang" href="" data-track>SELECT A REGION</a>
                              <a className="selectLang" href="" target="_blank" data-track>ARE YOU A PATIENT?</a>
                              <a className="xselectLang" href="" target="_blank" data-track>MY ACCOUNT</a>
                            </div>
                            <div className="socialIconsBottom">
                              <div className="btn-group"  style={{display:'block'}}>
                                <a href="#" target="_blank" data-track>
                                  <img src={Facbook} alt="Facebook" width="26" height="26" />
                                </a>
                              </div>
                          

                              <div className="btn-group"  style={{display:'block'}}>
                                <a href="#" target="_blank" data-track>
                                  <img src={Google_plus} alt="Twitter" width="26" height="26" />
                                </a>
                              </div>
                              <div className="btn-group"  style={{display:'block'}}>
                                <a href="#" target="_blank" data-track>
                                  <img src={Twitter} alt="LinkedIn" width="26" height="26" />
                                </a>
                              </div>
                              <div className="btn-group"  style={{display:'block'}}>
                                <a href="#" target="_blank" data-track>
                                  <img src={vimbo } alt="Instagram" width="26" height="26" />
                                </a>
                              </div>
                              <div className="btn-group" style={{display:'block'}}>
                                <a href="#" target="_blank" data-track>
                                  <img src={Tumblr} alt="YouTube" width="26" height="26" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

    )}
  </div>
</div></div></header>
    
         
  )
}

export default Header
