import React from 'react';
import getfitlogo from "./images/getfitl-removebg.png";

import Closewhiimg from "./images/closewhite.png";
import empower from "./images/empower.jpg";
import exerc from "./images/exrec.jpg";
import "./Commom&git.css";
import "./responsive&gitf.css";
import "./Skincar&git.css";
import "./Searchbox&git.css";
import "./Accessbil&git.css";
import "./Getfit.css";


const Getfit = () => {
  return (
    <div>
     <div className='headerdiv'>
     <div className="main">
 
    <div className="getfitbgimg">
      <div className="getfit-container">
        <div className="container">
          <div id="title" className="slide header">
          <div class="col-12 col-lg-8 conten1colget">
            <div className='row'>
            <div className='col-md-8 col-12'>
<div class="bannerPaddingRight">
                                        <h1 id='welcomspan' style={{letterSpacing:'1px'}}>
                                        <span className="bshape">GET FIT</span><br />
                  <span className="ultimate">Powered by Tesla</span>
                                        </h1>
                                        {/* <p className='_welcompro'>
                                          Welcome to Pro 1 Laser Canadas most
                                          technologically advanced <br/>
                                          and Health Canada approved IPL Manufacturer
                                        </p> */}
                                       
                                        {/* <p id='bttonp'>
                                          <a href="#" className="btn btn-white" id="_whitbutton" data-track="" style={{width:'185px',fontSize:'0.625rem'}}>+1 (604) 649-1234</a>
                                          <a aria-label="Opens the contact dialog" id="_bluebutton" href="#" className="btn btn-blue expertCall" data-track="" style={{width:'147.48px',fontSize:'0.625rem'}}>Contact Us</a>
                                        </p>
                                        */}
                                      </div></div>
                                      <div className='col-md-4 col-12'>
                                      <a  className="newsmeimgclass" data-track="link | Click | Home Page - Logo" href="/"><img src={getfitlogo} alt="Venus Concept Logo, links to homepage" width="210" height="50" style={{marginTop:'56px'}} /></a>
                                      </div>
                                      </div>
</div>
          </div>
        </div>
      </div>
    </div>
    <div className="getfit-welcome">
      <div className="high_intensity">
        <div className="container">
          <div className="getfit-logo">
            <h2 style={{ fontSize: '30px', fontWeight: '700', }}>
              GET FIT's High Intensity MMS technology
            </h2>
            <p>
              powered by Tesla-inspired electromagnetic energy, ignites an
              unprecedented journey towards muscle gain, body contouring, and
              holistic wellness. It's more than a treatment—it's a
              transformative experience designed to unlock your potential.
            </p>
          </div>
        </div>
      </div>
    </div>
    <section id="">
      <div className="empowering">
        <div className="container">
          <div className="rows">
            <div className="col-12 col-lg-6 empoweringcol6 ">
              <h2 className="">Empowering Confidence and Wellness for All</h2>
              <p className="">
                At GET FIT, our mission is to empower individuals from all
                walks of life with the confidence and well-being that come
                from a healthier body. We introduce a non-invasive,
                transformative approach to body contouring that seamlessly
                merges fat reduction with muscle building. With our
                cutting-edge High Intensity Magnetic Muscle Stimulation (MMS)
                technology, your aspirations for a revitalized body are well
                within reach.
              </p>
            </div>
            <div className="col-12 col-lg-6  empoweringcol6">
              <img className="commoncol6img" src={empower} alt="Empower" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="">
      <div className="tailoredgetfit">
        <div className="container">
          <div className="rows">
            <div className="col-md-8 col-12 empoweringcol6">
              <img className="commoncol6img" src={exerc} alt="Exercise" />
            </div>
            <div className="col-md-8 col-12 tailoredcol6">
              <div>
                <h2 className="">Tailored for Every Journey</h2>
              </div>
              <div>
                <p className="">
                  GET FIT embraces the diversity of human experiences. No
                  matter your gender, whether you're an athlete pursuing
                  excellence, a new mom embarking on a postpartum journey, or
                  someone redefining their wellness, GET FIT is your trusted
                  partner in transformation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="">
      <div className="younggetfit">
        <div className="rows">
          <div className="col-md-6 col-12"></div>
          <div className="col-md-6 col-12 crafingcol6">
            <div>
              <h2 className="">Crafting a Resilient You</h2>
            </div>
            <div>
              <p className="">
                At the heart of GET FIT's revolutionary approach lies the power
                of supramaximal contractions—an essential ingredient in
                fostering muscle resilience. When your muscles experience these
                contractions, a remarkable process of adaptation is set in
                motion. These supramaximal contractions act as a catalyst,
                compelling your muscles to respond by growing stronger and more
                enduring. This transformative journey is a gradual yet
                impactful one, contributing to an elevated level of endurance,
                heightened performance, and a body that's primed to conquer
                challenges with newfound strength.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="">
      <div className="magneticstimulationgetfit">
        
        <div className='magneticstimuladiv2222' style={{ background: '#a5adb7d9', boxShadow: '0px 0px 9px #fff' }}>
          <div>
            <h2 className="magneticstimulationh2">Harnessing Tesla Magnetic Stimulation</h2>
          </div>
          <div>
            <p className="">
              Tesla electromagnetic induction, named after the visionary
              scientist Nikola Tesla, drives GET FIT's transformative
              capabilities. This phenomenon generates intense magnetic
              fields that seamlessly permeate your muscles, triggering deep
              and potent contractions. These contractions, known as
              supramaximal contractions, transcend the limits of
              conventional exercise, unlocking the ultimate potential of
              your body.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="">
      <div className="powerofsciencegetfit">
        <div className="rows">
          <div className="col-12 col-lg-6"></div>
          <div className="col-12 col-lg-6 powercol6">
            <div>
              <h2 className="">Experience the Power of Science</h2>
            </div>
            <div>
              <p className="">
                In the realm of wellness and physique transformation, GET FIT
                stands as a pinnacle of scientific advancement. At the core of
                our innovation lies Tesla electromagnetic stimulation—a
                concept that revolutionizes the way we redefine the human
                form.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="">
      <div className="embracinggetfit">
        <div className="rows">
          <div className="col-12 col-lg-6 embracingcol6">
            <div>
              <h2 className="">Embracing Every Aspiration</h2>
            </div>
            <div>
              <p className="">
                Our advanced device knows no boundaries. It's perfect for those seeking swift fat reduction in areas like the stomach, and equally adept at reshaping and toning abdominal or gluteal muscles. But the versatility of GET FIT doesn't end there. With its innovative technology, GET FIT expands its transformative reach to include a spectrum of areas. Whether you're aiming to sculpt your thighs, define your arms, or achieve overall body harmony, GET FIT celebrates inclusivity by catering to diverse goals through its innovative technology.
              </p>
            </div>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
    </section>
    <div className="getfit-welcome">
      <div className="high_intensity">
        <div className="container">
          <div className="getfit-logo">
            <h2 style={{ fontSize: '30px', fontWeight: 700,}}>
              Delving Deeper into Supramaximal Contractions
            </h2>
            <p>
              By employing Tesla electromagnetic induction, GET FIT redefines muscle engagement. During each session, electromagnetic pulses stimulate your muscles to contract in a way that's not possible through voluntary movements alone. This groundbreaking approach results in a fusion of muscle fibers, sparking growth, toning, and overall enhancement.
            </p>
          </div>
        </div>
      </div>
    </div>
    <section id="">
      <div className="symphonyofgetfit">
        <div
          className="container"
          style={{
            boxShadow: '0px 0px 9px #fff',
            backgroundColor: '#a5adb7a8',
            padding: '30px',
          }}
        >
          <div className="rows">
            <div className="col-12 col-lg-6 empoweringcol6">
              <h2>A Symphony of Contractions</h2>
              <p>
                With each session at GET FIT, you're engaging in a symphony of
                controlled contractions. The electromagnetic energy precisely
                targets muscle groups, inducing contractions that are far more
                intensive than those produced during regular exercise. This
                process stimulates muscle adaptation, growth, and an overall
                improvement in your body's composition.
              </p>
            </div>
            <div className="col-12 col-lg-6 empoweringcol6">
              <img
                src={exerc}
                alt="Exercise"
                className="commoncol6img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="getfit-welcome">
      <div className="empowerbusiness">
        <div className="container">
          <div className="getfit-logo">
            <h2 style={{ fontSize: '30px', fontWeight: 700, }}>
              Empower Your Business with GET FIT
            </h2>
            <p>
              Are you ready to offer your clients an unparalleled wellness experience while propelling your business to new heights? Partnering with GET FIT means more than just embracing cutting-edge technology; it's a strategic investment in elevating your brand and services. By integrating GET FIT into your offerings, you open doors to a revolutionary approach that resonates with diverse aspirations—whether it's sculpting, rehabilitation, or overall wellness.
            </p>
            <p>
              Join the ranks of those who recognize the transformative potential of GET FIT's High-Intensity Magnetic Muscle Stimulation (MMS) technology. Elevate your practice, spa, gym, or wellness center by providing a solution that harmonizes with the evolving demands of your clientele. As a GET FIT partner, you gain access to a network of innovation, scientific expertise, and a brand that stands for empowerment, inclusivity, and well-being.
            </p>
            <p>
              Welcome to a partnership that empowers you, your clients, and your business with the future of wellness. Connect with us today and embark on a journey that not only transforms bodies but also redefines your business's success.
            </p>
          </div>
        </div>
      </div>
    </div>
      </div>
     </div>
    </div>
  )
}

export default Getfit
